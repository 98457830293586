/**
 * Created by matei on 13/11/2019
 */

import React, { PureComponent } from 'react';
import { event } from 'react-ga';
import { withRouter, Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import InputField from '../../components/inputfield';
import Heading from '../../components/heading';

import { authActionsFacade as authActions } from '../../actions/auth';

import getValidator from '../../lib/utils/validation';
import loginValidation from './login.validation';
import { connect } from 'react-redux';

const submit = (push, values, dispatch) => {
  /*
    event({
      category: 'Courses',
      action: 'Course registration',
      label: values.cursuri,
    });
  */
  dispatch(authActions.login(values)).then((res) => {
    if (res.errorCode === 411) {
      push('/login/set-password');
    } else if (res.errorCode === 403) {
      push('/login/set-password');
    } else if ((res.errorCode >= 200 && res.errorCode < 300) || res.payload?.success) {
      return dispatch(authActions.getUserProfile()).then((res) => {
        if (!res.error) {
          push('/login/success');
        }
      });
    }
  });
};

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: `/login`,
    text: `Login`,
  },
];

function mapStateToProps(state) {
  return {
    status: state.auth.status,
    payload: state.auth.payload,
    error: state.auth.error,
  };
}

@withRouter
@reduxForm({
  form: 'loginForm',
  asyncValidate: getValidator(loginValidation),
})
@connect(mapStateToProps)
export default class LoginPage extends PureComponent {
  constructor() {
    super();

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      history: {
        push,
      },
    } = this.props;

    return (
      <div className="loginPage">
        <Heading title="Login" links={ headingLinks } />

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>Login</h2>
                <p>Introduceti-va numele de utilizator si parola pentru a va loga pe platforma</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push)) }>
                  <div className="row">

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="email"
                          type="email"
                          component={ this.renderInput }
                          placeholder="E-Mail"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="password"
                          type="password"
                          component={ this.renderInput }
                          placeholder="Parola"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      Dacă nu aveți cont vă puteti crea unul aici: <Link to="/register">înregistrare</Link>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      Dacă v-ați uitat parola o puteți reseta aici: <Link to="/password-reset">resetare parolă</Link>
                    </div>

                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Trimite" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
