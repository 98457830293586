/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';

import './testimoniale.scss';

const testimonials = [
  {
    testimonial: 'Un curs pe cât de util, pe atât de plăcut și de ușor de parcurs!',
    name: 'Prof. Minodora Cozma',
    location: 'Neamț',
  },
  {
    testimonial: 'În urma participării la cursul online, mi-am aprofundat informațiile și am învățat cum să lucrez mai ușor cu un manual digital. Structura cursului a fost gândită bine, evaluarea de asemenea.',
    name: 'Prof. Cristina Bunda, Buzău',
    location: 'Buzău',
  },
  {
    testimonial: 'Util, atractiv, pe înțelesul tuturor!',
    name: 'Prof. Marioara Burunov',
    location: 'Ialomița',
  },
  {
    testimonial: 'Cursul a fost interesant. Mi-a ușurat munca la clasă, ceea ce interesează de fapt pe orice profesor. Mi-a prins bine faptul că am putut face cursul în ritmul meu, atunci când am avut timp, și nu încorsetat de un program strict.',
    name: 'Prof. Geanina Damaschin',
    location: 'Bacău',
  },
];

export default class Testimonials extends Component {
  componentDidMount() {
    /* eslint-disable */
    $('.all-testimonial').owlCarousel({
      autoplay: true,
      pagination: false,
      nav: true,
      navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
      dots: false,
      items: 1,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        768: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    });
    /* eslint-enable */
  }

  renderTestimonialItem(testimonial, name, location, index) {
    return (
      <div className="single-testimonial" key={index}>
        <p>{testimonial}</p>
        <div className="testimonial">
          <div className="inner">
            <div className="client-info">
              <h2>{name}</h2>
              <h3>{location}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="testimoniale testimonial-sec pt-100 pb-100">
        <div className="testimonial-sec-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="all-testimonial">
                {testimonials.map((testimonial, index) => this.renderTestimonialItem(testimonial.testimonial, testimonial.name, testimonial.location, index))}
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>
    );
  }
}
