/**
 * Created by matei on 13/11/2019
 */

import { required, email } from './../../lib/utils/validations';

const loginValidation = (data) => {
  return {
    email: [required, email],
    // password: [required],
  };
};

export default loginValidation;

