/**
 * Created by mateimisarca on 29/11/2019
 */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authActionsFacade as authActions } from '../../actions/auth';

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      authActions,
    ), dispatch),
  };
}

@withRouter
@connect(null, mapDispatchToProps)
export default class VerifyAccount extends PureComponent {
  constructor(props) {
    super(props);

    const {
      location: {
        search,
      },
      history: {
        push,
      },
      actions: {
        verifyToken,
      },
    } = props;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
console.log('-- ', query)
    verifyToken(query.token).then((res) => {
      if (!res.error) {
        push('/verify-account/success');
      }
    });
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}
