/**
 * Created by mateimisarca on 2019-06-12
 */

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import Heading from '../../components/heading';
import banner from '../../assets/images/banner_1920x350-3.jpg';
import InputField from '../../components/inputfield';

import getValidator from '../../lib/utils/validation';
import contactValidation from './contact.validation';
import { event } from 'react-ga';
import { contactActionsFacade as contactActions } from '../../actions/contact';

import './contact.scss';

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/contact',
    text: 'Contact',
  },
];

const submit = (push, values, dispatch) => {
  event({
    category: 'Messages',
    action: 'Message sent',
    label: 'Message sent',
  });
  dispatch(contactActions.addMessage(values)).then(() => push('/contact/success'));
};

@reduxForm({
  form: 'contactForm',
  asyncValidate: getValidator(contactValidation),
})
export default class ContactPage extends Component {
  constructor() {
    super();

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      history: {
        push,
      },
    } = this.props;

    return (
      <div className="contactPage">
        <Heading title="Formatori" links={ headingLinks } image={ banner } />

        <div className="contact-page-sec pt-100 pb-100">
          <div className="container">
            <form onSubmit={ handleSubmit(submit.bind(this, push)) }>

              <div className="row">
                <div className="col-md-12">
                  <div className="contact-info">
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="contact-info-text">
                        <h2>e-mail</h2>
                        <a href="mailto:admin@cursuridigitale.ro" target="_top">admin@cursuridigitale.ro</a>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="contact-info-text">
                        <h2>telefon</h2>
                        <a href="tel:0040374833500">0374-833-500</a>
                        <br />
                        <a href="tel:0040752548372">0752-548-372</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="contact-page-form">
                  <h2>Trimiteti mesajul</h2>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <Field
                        name="nume"
                        type="text"
                        component={ this.renderInput }
                        placeholder="Nume"
                        className="form-element"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <Field
                        name="prenume"
                        type="text"
                        component={ this.renderInput }
                        placeholder="Prenume"
                        className="form-element"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <Field
                        name="telefon"
                        type="text"
                        component={ this.renderInput }
                        placeholder="Numar telefon"
                        className="form-element"
                      />

                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <Field
                        name="email"
                        type="email"
                        component={ this.renderInput }
                        placeholder="E-Mail"
                        className="form-element"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 message-input">
                    <div className="single-input-field">
                      <Field
                        name="mesaj"
                        type="text"
                        component={ this.renderInput }
                        placeholder="Mesajul dumneavoastra"
                        className="form-element"
                        multiline
                      />
                    </div>
                  </div>
                  <div className="single-input-fieldsbtn">
                    <input type="submit" value="Trimite" />
                  </div>
                </div>


              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
