/**
 * Created by matei on 14/11/2019
 */

import React, { PureComponent } from 'react';
import { event } from 'react-ga';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import InputField from '../../components/inputfield';
import Heading from '../../components/heading';

import { authActionsFacade as authActions } from '../../actions/auth';

import getValidator from '../../lib/utils/validation';
import registerValidation from './register.validation';
import { connect } from 'react-redux';

const submit = (push, values, dispatch) => {
  /*
    event({
      category: 'Authentication',
      action: 'Registration',
      label: values.cursuri,
    });
  */
  dispatch(authActions.register(values)).then((res) => {
    if (!res.error) {
      push('/register/success');
    }
  });
};

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: `/register`,
    text: `Register`,
  },
];

function mapStateToProps(state) {
  return {
    status: state.auth.status,
    payload: state.auth.payload,
    error: state.auth.error,
  };
}

@withRouter
@reduxForm({
  form: 'registerForm',
  asyncValidate: getValidator(registerValidation),
})
@connect(mapStateToProps)
export default class LoginPage extends PureComponent {
  constructor() {
    super();

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      history: {
        push,
      },
    } = this.props;

    return (
      <div className="loginPage">
        <Heading title="Login" links={ headingLinks } />

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>Register</h2>
                <p>Introduceti-va datele pentru a va crea un cont</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push)) }>
                  <div className="row">

                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="email"
                          type="email"
                          component={ this.renderInput }
                          placeholder="E-Mail"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="phone"
                          type="text"
                          component={ this.renderInput }
                          placeholder="Nr. Telefon"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="name"
                          type="text"
                          component={ this.renderInput }
                          placeholder="Nume"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="firstname"
                          type="text"
                          component={ this.renderInput }
                          placeholder="Prenume"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="password"
                          type="password"
                          component={ this.renderInput }
                          placeholder="Parola"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          component={ this.renderInput }
                          placeholder="Confirmare Parola"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Trimite" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
