/**
 * Created by mateimisarca on 24/07/2018
 */

import { errorActionsFacade as errorActions } from './../../actions/error';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) =>
    next => (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      return promise.then(
        result => {
          if (result.status > 299) {
            return Promise.reject(result);
          }

          return result.json();
          /*
                    return result.text().then(_result => {
                      return _result
                        ? { myResult: JSON.parse(_result), url: result.url }
                        : { myResult: {}, url: result.url };
                    });
          */
        },
        (error) => {
          console.error('MIDDLEWARE ERROR:', error);
          dispatch({ ...rest, error, type: FAILURE });
          dispatch(errorActions.error({ ...rest, error }));

          return Promise.reject(error);
        },
      )
        .catch(err => {
          if (err?.status === 401) {
            dispatch({
              ...rest,
              errorCode: err?.status,
              error: 'Unauthorized',
              type: FAILURE,
            });
          }

          return err.json().then(_err => {
            return Promise.reject({
              errorCode: err?.status,
              error: _err,
              status: err.status,
              url: err.url,
            });
          });
        })
        .then((myResult) => dispatch({ ...rest, payload: myResult, type: SUCCESS }))
        .catch(err => {
          // console.log("Catched Error: ", err);

          return dispatch({
            ...rest,
            errorCode: err?.errorCode,
            error: err?.error,
            type: FAILURE,
          });
        });
    };
}


