import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import $ from 'jquery';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { initialize, pageview } from 'react-ga';
import CookieConsent from 'react-cookie-consent';

import { history } from './redux/create';

initialize('UA-142509634-1');

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);
window.imagesLoaded = imagesLoaded;

/*eslint-disable */
import 'bootstrap/dist/js/bootstrap';
// import './vendor/js/bootstrap.min.js';
import './vendor/js/isotope.pkgd.min.js';
import './vendor/js/jquery.magnific-popup.min.js';
import './vendor/js/owl.carousel.min.js';
import './vendor/js/owl.animate.js';
import './vendor/js/jquery.scrollUp.min.js';
import './vendor/js/jquery.counterup.min.js';
import './vendor/js/modernizr.min.js';
import './vendor/js/waypoints.min.js';
import './vendor/js/jquery.meanmenu.min.js';
import './vendor/js/imagesloaded.pkgd.min.js';
import './vendor/js/custom.js';


// import './vendor/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/scss/bootstrap-grid.scss';
import 'bootstrap/scss/bootstrap-reboot.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import './vendor/css/font-awesome.min.css';
import './vendor/css/magnific-popup.css';
import './vendor/css/owl.carousel.min.css';
import './vendor/css/animate.css';
import './vendor/css/main.css';
import './vendor/css/meanmenu.min.css';
import './vendor/css/responsive.css';

import './app.css';

import Header from './components/header';
import Footer from './components/footer';
import ToastNotification from './components/toast-notifications';

import AppContainer from './pages/app-container';
import AdminContainer from './containers/adminContainer';

import HomePage from './pages/home-page';
import CursuriPage from './pages/cursuri-page';
import CursPage from './pages/curs-page';
import InscrierePage from './pages/inscriere-page';
import InscriereSuccessPage from './pages/inscriere-success-page';
import TraineriPage from './pages/traineri-page';
import PartnersPage from './pages/partners-page';
import ContactPage from './pages/contact-page';
import ContactSuccessPage from './pages/contact-success-page';
import TermeniSiConditiiPage from './pages/termeni-si-conditii-page';
import P404 from './pages/404-page';
import LoginPage from './pages/login-page';
import RegisterPage from './pages/register';
import RegisterSuccessPage from './pages/register-success-page';
import CursWrapper from './pages/curs/curs-wrapper';
import LoginSuccessPage from './pages/login-success-page';
import VerifyAccount from './pages/verify-account';
import VerifySuccessPage from './pages/verify-success-page';
import LoginPasswordPage from './pages/login-password-page';
import ResetPassword from './pages/reset-password';
import ResetPasswordSuccessPage from './pages/reset-password-success-page';
import Profile from './pages/profile/Profile';
import ProfileSuccessPage from './pages/profile-success-page';
import PasswordReset from './pages/password-reset';
import PasswordResetSuccess from './pages/password-reset-success';
import Series from './pages/series';

/*eslint-enable */

export default class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  render() {
    const {
      store,
    } = this.props;

    // const history = createHistory();

    return (
      <Provider store={ store }>
        <ConnectedRouter history={ history }>
          <div className='app'>
            <AppContainer>
              <ToastNotification>
                <Header />

                <Switch>
                  <Route path="/login/success" component={ LoginSuccessPage } />
                  <Route path="/login/set-password" component={ LoginPasswordPage } />
                  <Route path="/login" component={ LoginPage } />
                  <Route path="/register/success" component={ RegisterSuccessPage } />
                  <Route path="/register" component={ RegisterPage } />

                  <Route path="/password-reset/success" component={ PasswordResetSuccess } />
                  <Route path="/password-reset" component={ PasswordReset } />

                  <Route path="/reset-password/success" component={ ResetPasswordSuccessPage } />
                  <Route path="/reset-password" component={ ResetPassword } />

                  <Route path="/profile/success" component={ ProfileSuccessPage } />
                  <Route path="/profile" component={ Profile } />

                  <Route path="/verify-account/success" exact component={ VerifySuccessPage } />
                  <Route path="/verify-account" component={ VerifyAccount } />

                  <Route path="/" exact component={ HomePage } />

                  <Route path="/cursuri" exact component={ CursuriPage } />
                  <Route path="/cursuri/:course" exact component={ CursPage } />

                  <Route path="/cursuri/:course" component={ CursWrapper } />
                  <Route path="/series/:course" component={ Series } />


                  <Route path="/inscriere/success" exact component={ InscriereSuccessPage } />
                  <Route path="/inscriere" exact component={ InscrierePage } />
                  <Route path="/inscriere-limbi-straine" exact component={ InscrierePage } />
                  <Route path="/inscriere/:course" exact component={ InscrierePage } />
                  <Route path="/inscriere-materiale-educationale" exact component={ InscrierePage } />
                  <Route path="/inscriere-materiale-educationale-limbi-straine" exact component={ InscrierePage } />

                  <Route path="/admin" component={ AdminContainer } />

                  <Route path="/traineri" exact component={ TraineriPage } />
                  <Route path="/parteneri" exact component={ PartnersPage } />
                  <Route path="/contact" exact component={ ContactPage } />
                  <Route path="/contact/success" exact component={ ContactSuccessPage } />
                  <Route path="/termeni-si-conditii" exact component={ TermeniSiConditiiPage } />
                  <Route component={ P404 } />

                </Switch>
                <CookieConsent buttonText="Accept">
                  <div>
                <span>
              cursuridigitale.ro utilizează cookie-uri pentru a vă oferi o experiență de utilizare mai bună. Utilizând
              în continuare site-ul vă exprimați acordul asupra modului în care utilizăm cookie-urile
                </span>
                    <span>
                  <Link to="/termeni-si-conditii" style={ {
                    color: 'white',
                    fontWeight: 'bold',
                    marginLeft: '20px',
                  } }>Citeste mai mult</Link>
                </span>
                  </div>
                </CookieConsent>
                <Footer />
              </ToastNotification>
            </AppContainer>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}
