/**
 * Created by mateimisarca on 26/11/2019
 */

import React, { Fragment, PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { find, findIndex, includes, isEqual, every } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Steps from '../../../components/steps';
import CursView from '../curs-view';
import CursMaterials from '../curs-materials';
import CursGrila from '../curs-grila';

import { coursesActionsFacade as coursesActions } from '../../../actions/courses';
import { resultsActionsFacade as resultsActions } from '../../../actions/results';
import ModuleApplication from './module-aplicatie';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  course: state.courses.course,
  modules: state.courses.modules,
  result: state.results.result,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      coursesActions,
      resultsActions,
    ), dispatch),
  };
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class Module extends PureComponent {
  constructor(props) {
    super(props);

    const {
      course,
      modules,
      actions: {
        getUserResults,
      },
      match: {
        params,
      },
      location: {
        pathname,
      },
    } = props;

    const module = find(modules, o => o._id === params?.moduleId);
    const currentStep = this.getStepIndex(pathname, module?.elements);

    this.state = {
      module,
      currentStep,
    };

    getUserResults({
      moduleId: params.moduleId,
      courseId: course._id,
    });

    this.updateStep = this.updateStep.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params,
      },
      course,
      modules,
      actions: {
        getUserResults,
      },
      location: {
        pathname,
      },
    } = this.props;

    if (prevProps.match?.params?.moduleId !== params.moduleId) {
      const module = find(modules, o => o._id === params?.moduleId);
      const currentStep = this.getStepIndex(pathname, module?.elements);

      getUserResults({
        moduleId: params.moduleId,
        courseId: course._id,
      }).then(() => this.setState({
          module,
          currentStep,
        }),
      );
    } else {
      const module = find(modules, o => o._id === params?.moduleId);
      const currentStep = this.getStepIndex(pathname, module?.elements);
      if (currentStep !== this.state.currentStep) {
        this.setState({
          currentStep,
        });
      }
    }
  }

  getStepIndex(pathname, elements) {
    let lastPath = pathname.split('/');
    lastPath = lastPath[lastPath.length - 1];
    return findIndex(elements, o => isEqual(lastPath, o.link));
  }

  updateStep(element, id) {
    const {
      actions: {
        updateStep,
        getCourseResults,
      },
      match: {
        params,
      },
      history: {
        push,
      },
    } = this.props;

    return new Promise((resolve, reject) => {
      updateStep(element, id)
        .then(res => {
          const {
            result: {
              courseId,
            },
            updated,
          } = res.payload;
          getCourseResults({ courseId }).then(res2 => {
            const {
              result,
            } = res2.payload;
            const allTrue = every(result, 'done');
            if (updated && allTrue) {
              push(`/cursuri/${ params.course }/success`);
            }
          });
          resolve();
        })
        .catch(err => reject(err));
    });


  }

  render() {
    const {
      currentStep,
      module,
    } = this.state;
    const {
      history: {
        push,
      },
      match: {
        path,
        url,
      },
      isSubscribed,
      result,
    } = this.props;

    if (!result) {
      return null;
    }

    return (
      <Fragment>
        <Steps
          currentStep={ currentStep }
          steps={ module?.elements }
          result={ result }
          onStepChange={ (step) => this.setState({
            currentStep: step,
          }, () => push(`${ url }/${ module?.elements[step].link }`)) }
        />
        <div className="container">
          <Switch>
            <Route
              path={ `${ path }` }
              exact
              render={ (props) => (
                <CursView
                  { ...props }
                  isSubscribed={ isSubscribed }
                  course={ module }
                  result={ result }
                  parentPath={ url }
                  push={ push }
                />
              ) }
            />
            { module?.elements.map(element => {
              if (isEqual(element.type, 'materiale-curs')) {
                return (
                  <Route
                    key={ element._id }
                    path={ `${ path }/${ element.link }` }
                    exact
                    render={ (props) => (
                      <Fragment>
                        <CursMaterials
                          { ...props }
                          isSubscribed={ isSubscribed }
                          element={ element }
                          parentPath={ url }
                          push={ push }
                          updateStep={ this.updateStep }
                          result={ result }
                        />
                        { result?.elements[currentStep]?.courseElementId &&
                        result.elements[currentStep].done &&
                        module?.elements[currentStep + 1] && (
                          <div
                            className="single-input-fieldsbtn"
                            style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' } }
                            onClick={ () => {
                              const {
                                match: {
                                  params,
                                },
                                history: {
                                  push,
                                },
                              } = this.props;
                              push(`/cursuri/${ params.course }/${ params.moduleId }/${ module?.elements[currentStep + 1]?.link }`);
                            } }
                          >
                            <input type="submit" value="Pasul următor" />
                          </div>
                        ) }
                      </Fragment>
                    ) }
                  />
                );
              } else if (isEqual(element.type, 'grila')) {
                return (
                  <Route
                    key={ element._id }
                    path={ `${ path }/${ element.link }` }
                    exact
                    render={ (props) => (
                      <Fragment>
                        <CursGrila
                          { ...props }
                          isSubscribed={ isSubscribed }
                          element={ element }
                          parentPath={ url }
                          push={ push }
                          updateStep={ this.updateStep }
                          result={ result }
                        />
                        { result?.elements[currentStep]?.courseElementId &&
                        result.elements[currentStep].done &&
                        module?.elements[currentStep + 1] && (
                          <div
                            className="single-input-fieldsbtn"
                            style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' } }
                            onClick={ () => {
                              const {
                                match: {
                                  params,
                                },
                                history: {
                                  push,
                                },
                              } = this.props;
                              push(`/cursuri/${ params.course }/${ params.moduleId }/${ module?.elements[currentStep + 1]?.link }`);
                            } }
                          >
                            <input type="submit" value="Pasul următor" />
                          </div>
                        ) }
                      </Fragment>
                    ) }
                  />
                );
              } else if (isEqual(element.type, 'aplicatie-libera')) {
                return (
                  <Route
                    key={ element._id }
                    path={ `${ path }/${ element.link }` }
                    exact
                    render={ (props) => (
                      <ModuleApplication
                        { ...props }
                        isSubscribed={ isSubscribed }
                        element={ element }
                        parentPath={ url }
                        push={ push }
                        updateStep={ this.updateStep }
                        result={ result }
                        module={ module }
                        currentStep={ currentStep }
                      />
                    ) }
                  />
                );
              }
            }) }
          </Switch>
        </div>
      </Fragment>
    );
  }
}
