/**
 * Created by mateimisarca on 24/11/2019
 */

import React, { Component } from 'react';
import cx from 'classnames';

export default class Choice extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    const defaultAnswers = props.answers.map((answer, index) => props.itemAnswers ? props.itemAnswers?.answers[index].isCorrect : false);

    const correctAnswers = props.answers.map(() => null);

    this.state = {
      answers: defaultAnswers,
      correctAnswers,
      allCorrect: props.itemAnswers ? true : null,
    };
  }

  onSubmit() {
    const { answers } = this.state;
    const { onSolved, question } = this.props;

    const correctAnswers = new Array(answers.length);
    let allCorrect = true;
    for (let i = 0; i < answers.length; i++) {
      correctAnswers[i] = this.props.answers[i].isCorrect === answers[i];
      if (this.props.answers[i].isCorrect !== answers[i]) {
        allCorrect = false;
      }
    }

    if (allCorrect) {
      const raspunsuri = [];
      for (let i = 0; i < answers.length; i++) {
        raspunsuri.push({
          answer: this.props.answers[i].answer,
          isCorrect: this.props.answers[i].isCorrect,
          providedAnswer: answers[i],
        });
      }
      onSolved({
        question,
        answers: raspunsuri,
        resolved: allCorrect,
      });
    }

    this.setState({
      correctAnswers,
      allCorrect,
    });
  }

  onCheckboxClick(index) {
    let newAnswers;
    if (this.props.choiceType === 'multiple-choice') {
      newAnswers = this.state.answers;
      newAnswers[index] = !newAnswers[index];
    }
    else {
      newAnswers = new Array(this.state.answers.length).fill(false);

      newAnswers[index] = true;
    }

    this.setState({
      answers: newAnswers,
    });
  }

  render() {
    const { question, answers } = this.props;
    const { correctAnswers, allCorrect } = this.state;
    const classNames = cx('card', {
      'border-success': allCorrect === true,
      'border-danger': allCorrect === false,
    });
    const headerClassNames = cx('card-header', {
      'bg-success': allCorrect === true,
      'bg-danger': allCorrect === false,
      'text-white': allCorrect !== null,
    });

    return (
      <div className="container pl-0 pr-0 mt-3 ">
        <div className={ classNames }>
          <div className={ headerClassNames }>
            { question }
          </div>
          <div className="card-body">
            { answers.map((answer, index) => (
              <div key={ answer._id } className="custom-control custom-checkbox">
                <input
                  className={ cx('custom-control-input', {
                    'is-valid': correctAnswers[index] === true,
                    'is-invalid': correctAnswers[index] === false,
                  }) }
                  type="checkbox"
                  disabled={ allCorrect }
                  checked={ this.state.answers[index] }
                  id={ `defaultCheck${ answer._id }` }
                  onChange={ this.onCheckboxClick.bind(this, index) }
                />
                <label className="custom-control-label" htmlFor={ `defaultCheck${ answer._id }` }>
                  { answer.answer }
                </label>
              </div>
            )) }
            <div className="single-input-fieldsbtn" onClick={ this.onSubmit }>
              <input type="submit" value="Verifică" disabled={ allCorrect } />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
