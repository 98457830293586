/**
 * Created by mateimisarca on 01/12/2019
 */

import { includes } from 'lodash';

const initialState = {
  error: null,
};

export default function errors(state = initialState, action) {

  if (includes(action.type, 'fail') && action.error) {
    console.log('@@@@!!', action);
    return {
      ...state,
      error: action.error,
    };
  }

  return state;
}
