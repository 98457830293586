/**
 * Created by mateimisarca on 01/12/2019
 */

import React, { PureComponent, useEffect } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  error: state.errors.error,
});

function ToastContainer(
  {
    activeToast,
  },
) {
  const { addToast } = useToasts();

  useEffect(() => {
    if (activeToast.text) {
      addToast(activeToast.text, {
        appearance: 'error',
        autoDismiss: true,
        ...activeToast.options,
      });
    }
  }, [activeToast]);

  return <React.Fragment />;
}

ToastContainer.propTypes = {
  activeToast: PropTypes.shape({
    options: PropTypes.shape({}),
    text: PropTypes.string.isRequired,
  }).isRequired,
};

class ToastNotifications extends PureComponent {
  constructor() {
    super();

    this.state = {
      error: null,
    };
  }

  componentWillReceiveProps(nextProps) {

    if (!isEqual(this.props.error, nextProps.error)) {
      this.setState({
        error: nextProps.error,
      });
    }
  }

  render() {
    const {
      error,
    } = this.state;

    return (
      <ToastProvider>
        { error && <ToastContainer activeToast={ {
          options: {},
          text:  error?.message || error?.error?.message || error?.error || error,
        } } /> }
        { this.props.children }
      </ToastProvider>
    );
  }
}

export default connect(mapStateToProps)(ToastNotifications);
