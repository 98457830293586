import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './login-password-page.scss';

export default class LoginPasswordPage extends Component {
  render() {
    return (
      <div className="inscriereSuccess error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>Atenție</h1>
                <h2>Va rugăm să vă verificați mailul și să vă confirmați contul.</h2>
                <div className="inscriereFail-icon fas fa-times-circle" />
                <Link to="/">Inapoi la site</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
