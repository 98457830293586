/**
 * Created by mateimisarca on 21.03.2021
 */


import { find } from 'lodash';

import { ADMIN, COURSES } from './../constants';
import elements from '../pages/curs/elements';

const initialState = {
  status: null,
  courses: [],
  subscriptions: {
    count: 0,
    rows: [],
    currentPage: 1,
    pages: 1,
    itemsPerPage: [10, 20, 50],
    perPage: 10,
  },
  results: {
    count: 0,
    rows: [],
    currentPage: 1,
    pages: 1,
    itemsPerPage: [10, 20, 50],
    perPage: 10,
  },
  series: {
    count: 0,
    rows: [],
    currentPage: 1,
    pages: 1,
    itemsPerPage: [10, 20, 50],
    perPage: 10,
  },
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case ADMIN.getCourses:
      return {
        ...state,
        status: 'loading',
      };

    case ADMIN.getCoursesSuccess:
      return {
        ...state,
        status: 'success',
        courses: action.payload.result,
      };

    case ADMIN.getCoursesFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case ADMIN.getSubscriptions:
      return {
        ...state,
        status: 'loading',
      };

    case ADMIN.getSubscriptionsSuccess:
      return {
        ...state,
        status: 'success',
        subscriptions: Object.assign({}, state.subscriptions, action.payload.result),
      };

    case ADMIN.getSubscriptionsFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case ADMIN.getSeries:
      return {
        ...state,
        status: 'loading',
      };

    case ADMIN.getSeriesSuccess:
      return {
        ...state,
        status: 'success',
        series: Object.assign({}, state.series, action.payload.result),
      };

    case ADMIN.getSeriesFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case ADMIN.getResults:
      return {
        ...state,
        status: 'loading',
      };

    case ADMIN.getResultsSuccess:
      return {
        ...state,
        status: 'success',
        results: Object.assign({}, state.results, action.payload.result),
      };

    case ADMIN.getResultsFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    default:
      return state;
  }
}
