/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Menu from './../menu';

import logo from './../../assets/images/cursuri-digitale-logo2-07.jpg';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

@connect(mapStateToProps)
export default class Header extends Component {
  render() {
    const {
      isLoggedIn,
    } = this.props;

    return (
      <header>
        <div className="header-top" style={ { backgroundColor: 'crimson' } }>
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-8">
                <div className="header-left">
                  <ul>
                    <li><i className="fa fa-phone" />
                      <a href="tel:0040374833500">0374-833-500</a>
                    </li>
                    <li><i className="fa fa-envelope-o" />
                      <a href="mailto:admin@cursuridigitale.ro" target="_top">admin@cursuridigitale.ro</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 col-sm-4">
                <div className="header-right-div">
                  <div className="soical-profile">
                    <span className="social-title">Urmareste-ne:</span>
                    <ul>
                      <li><a href="https://www.facebook.com/clubulprofesorilorromania" target="_blank"><i
                        className="fa fa-facebook" /></a></li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hd-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12 col-xs-8">
                <div className="logo">
                  <Link to="/">
                    <img style={ { height: '50px' } } src={ logo } alt="" />
                    <span style={ { color: '#14cdbb' } }>Cursuri</span>
                    <span style={ { color: '#f43d39' } }>Digitale</span>
                  </Link>
                </div>
              </div>
              <div className="mobile-nav-menu"></div>
              <div className="col-md-7 col-sm-9 menu-center">
                <div className="menu">
                  <Menu />
                  {/*<div className="search-bar-icon">*/ }
                  {/*  <div className="site-search">*/ }
                  {/*    <span data-toggle="dropdown"><i className="fa fa-search"></i></span>*/ }
                  {/*    <div className="search-forum dropdown-menu animation slideUpIn">*/ }
                  {/*      <form action="#">*/ }
                  {/*        <input placeholder="Search For Site" type="text">*/ }
                  {/*          /!*<input type="submit" value="Go"/>*!/*/ }
                  {/*        </input>*/ }
                  {/*      </form>*/ }
                  {/*    </div>*/ }
                  {/*  </div>*/ }
                  {/*</div>*/ }
                </div>
              </div>
              <div className="col-md-3 col-sm-3 applay-button-area">
                <div className="applay-button">
                  { isLoggedIn ? (
                    <Link to="/profile">Profilul meu</Link>
                  ) : (
                    <Link to="/login">Log In</Link>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

