/**
 * Created by mateimisarca on 2019-06-11
 */

import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import banner from '../../assets/images/banner_1920x350-1.jpg';

export default class Heading extends Component {
  render() {
    const {title, links, image} = this.props;
    const bg = image || banner;

    return (
      <div className="pagehding-sec" style={{backgroundImage: `url(${bg})`}}>
        <div className="images-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-heading">
                <h1>{title}</h1>
              </div>
              <div className="breadcrumb-list">
                <ul>
                  {links.map((link, index) => (
                    <li key={index}><Link to={link.link}>{link.text}</Link></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
