/**
 * Created by mateimisarca on 05/12/2019
 */

import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import qs from 'qs';

import InputField from '../../components/inputfield';
import Heading from '../../components/heading';

import getValidator from '../../lib/utils/validation';
import resetPasswordValidation from './reset-password.validation';

import { authActionsFacade as authActions } from '../../actions/auth';

const submit = (push, token, values, dispatch) => {
  /*
    event({
      category: 'Courses',
      action: 'Course registration',
      label: values.cursuri,
    });
  */
  dispatch(authActions.resetUserPassword({
    ...values,
    token,
  })).then((res) => {
    console.log('res ', res);
    if (res.payload?.success) {
      push('/reset-password/success');
    }
  });
};

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: `/reset-password`,
    text: `Reset Password`,
  },
];

@withRouter
@reduxForm({
  form: 'resetPasswordForm',
  asyncValidate: getValidator(resetPasswordValidation),
})
// @connect(mapStateToProps)
export default class ResetPassword extends PureComponent {
  constructor() {
    super();

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      history: {
        push,
      },
      location: {
        search,
      },
    } = this.props;

    const query = qs.parse(search, { ignoreQueryPrefix: true });

    return (
      <div className="loginPage">
        <Heading title="Resetare Parolă" links={ headingLinks } />

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>Resetare Parolă</h2>
                <p>Introduceți noua parolă</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push, query.token)) }>
                  <div className="row">

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="password"
                          type="password"
                          component={ this.renderInput }
                          placeholder="Parola"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          component={ this.renderInput }
                          placeholder="Confirmare Parola"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Trimite" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
