/**
 * Created by matei on 17/11/2019
 */

import React, { PureComponent } from 'react';
import { string, arrayOf, object, func, bool } from 'prop-types';

import CourseStep from '../../components/course-step';

import photo from '../../assets/images/banner_420x230_4.jpg';
import icon from '../../assets/icon/locked.png';

import './curs-view.scss';
import { findIndex } from 'lodash';

export default class CursViewModules extends PureComponent {
  static propTypes = {
    parentPath: string,
    push: func,
    modules: arrayOf(object),
  };

  constructor(props) {
    super(props);

    const { modules, getUserResults } = props;
    const doneModules = new Array(4).fill(false);

    for (let i = 0; i < modules.length; i++) {
      doneModules.push(false);
      getUserResults({
        moduleId: modules[i]?._id,
        courseId: modules[i]?.courseId,
      }).then(res2 => {
        const index = findIndex(modules, o => o._id === res2.payload.result.moduleId);
        if (index > -1 && res2.payload.result.done) {
          doneModules[index] = true;
          this.setState({
            doneModules,
          });
        }
      });
    }

    this.state = {
      doneModules,
    };
  }

  render() {
    const {
      parentPath,
      push,
      modules,
    } = this.props;
    const { doneModules } = this.state;

    return (
      <div className="courseView pt-100 pb-70">
        <div className="row">
          <div className="col-md-12">
            <div className="why-choose-all courseView-steps">
              { modules.map((step, index) => {
                let photoC;
                try {
                  photoC = require(`./../../assets/moduleImg/${ step.imageBanner }_small.jpg`);
                } catch (err) {
                  photoC = step.photo;
                }

                return (
                  <CourseStep
                    key={ index }
                    title={ 'ACCESEAZĂ' }
                    disabled={ index !== 0 && !doneModules[index - 1] && step.title !== 'RESURSE' }
                    // content={ /*step.description*/ 'ACCESEAZĂ' }
                    photo={ photoC || photo }
                    icon={ step.icon || icon }
                    onClick={ () => push(`${ parentPath }/${ step._id }`) }
                  />
                );
              }) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
