import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './password-reset-success.scss';

export default class PasswordResetSuccess extends Component {
  render() {
    return (
      <div className="registerSuccess error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>Felicitări!</h1>
                <h2>Vă rugăm să vă verificați mailul și să vă setați noua parolă.</h2>
                <div className="registerSuccess-icon fa fa-check-circle" />
                <Link to="/">Inapoi la site</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
