/**
 * Created by mateimisarca on 24/07/2018
 */

import { LOADING_OVERLAY } from './../constants';

export function showLoadingScreen(visibility) {
  return {
    type: LOADING_OVERLAY.showLoadingScreen,
    payload: visibility,
  };
}

export const LoadingScreenActionsFacade = {
  showLoadingScreen,
};

