/**
 * Created by mateimisarca on 2019-06-11
 */

import React, { Component } from 'react';

import Heading from './../../components/heading';
import { courses } from '../../constants/courses';
import CourseItem from '../../components/course-item';

// import banner from './../../assets/images/banner-curs-dictie.jpg';

export default class CursuriPage extends Component {
  render() {
    const headingLinks = [
      {
        link: '/',
        text: 'Acasa',
      },
      {
        link: '/cursuri',
        text: 'Cursuri',
      },
    ];

    return (
      <div>
        <Heading title="Cursurile Noastre" links={ headingLinks } />
        <div className="course-page-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              { courses.map((course, index) => (
                <div className="col-md-4 col-sm-6" key={ index }>
                  <CourseItem { ...course } />
                </div>
              )) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
