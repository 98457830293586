/**
 * Created by mateimisarca on 24.03.2021
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { capitalize, map, find, debounce, cloneDeep } from 'lodash';
import { Card } from 'react-bootstrap';

import { adminActionsFacade as adminActions } from '../../actions/admin';
import DropdownMenu from '../../components/dropdown-menu';
import Dropdown from '../../components/dropdown';
import DataTable from '../../components/data-table';
import InputField from '../../components/inputfield';

import './results-admin-page.scss';

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      adminActions,
    ), dispatch),
  };
}

const mapStateToProps = state => ({
  courses: state.admin.courses,
  results: state.admin.results,
});

@connect(mapStateToProps, mapDispatchToProps)
export default class ResultsAdminPage extends Component {
  constructor(props) {
    super(props);

    const { getResults, getCourses } = props.actions;

    getCourses();
    getResults({ courseId: null }).then(() => this.setState({ isDataLoading: false }));
    ;

    this.state = {
      pagination: {},
      courseId: null,
      find: '',
      expandedRow: null,
      isDataLoading: true,
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.getResults = this.getResults.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  onDropdownClick() {
  }

  onItemClick(id, value) {
    const { getResults } = this.props.actions;
    const { pagination, find } = this.state;

    this.setState({
      pagination: {},
      courseId: id,
      expandedRow: null,
      isDataLoading: true,
    });

    getResults({ courseId: id, ...pagination, find }).then(() => this.setState({ isDataLoading: false }));
  }

  handleFindChange = debounce(
    value => {
      const { pagination, courseId } = this.state;
      const { getResults } = this.props.actions;
      this.setState({ expandedRow: null, find: value, isDataLoading: true });
      getResults({ courseId, ...pagination, find: value }).then(() => this.setState({ isDataLoading: false }));
    },
    1000,
    { leading: false },
  );

  getResults(pagination) {
    const { getResults } = this.props.actions;
    const { courseId, find } = this.state;

    const newPagination = Object.assign({}, this.state.pagination, pagination);
    this.setState({
      pagination: newPagination,
      expandedRow: null,
      isDataLoading: true,
    });
    getResults({
      courseId,
      ...newPagination,
      find,
    }).then(() => this.setState({ isDataLoading: false }));
  }

  renderDropdown(courses, placeholder, selectedCourse) {
    return (
      <Dropdown
        className="resultsAdmin-dropdown"
        noPadding
        label={ selectedCourse || <span style={ { color: '#898989', textTransform: 'none' } }>
            { capitalize(placeholder) }
          </span>
        }
        size="large"
        onClick={ this.onDropdownClick }
        onClickOutside={ this.onDropdownClick }
        blockButton
        position="right"
        theme="dark"
      >
        <DropdownMenu
          items={ map(courses, course => ({
            id: course._id,
            name: course.title,
          })) }
          onItemClick={ this.onItemClick/*.bind(this, input.onBlur, input.name)*/ }
        />
      </Dropdown>
    );
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
        disabled={ disabled }
      />
    );
  }

  renderExpandedElement(elements) {
    if (!elements) return null;

    return (
      <div style={ { border: '2px solid #152935', margin: '-0.3rem' } }>
        <DataTable
          columns={ {
            'module': {
              units: 'text',
            },
            'created': {
              units: 'date',
            },
            'updated': {
              units: 'date',
            },
            'done': {
              units: 'date',
            },
          } }
          rows={ elements }
          // count={ results.count }
          // currentPage={ results.currentPage }
          // pages={ results.pages }
          pagination={ false }
          // onTableUpdate={ this.getResults }
          // itemsPerPage={ results.itemsPerPage }
          // perPage={ results.perPage }
          // totalItems={ results.count }
          // expanded={ 2 }
          // expandedElement={ this.renderExpandedElement(results.rows[2]?.modules) }
          // onRowClick={ (id, row) => goTo(`${ page }/${ id }`) }
        />
      </div>
    );
  }

  renderDataTable() {
    const { results, actions: { exportResults } } = this.props;
    const { expandedRow, isDataLoading } = this.state;

    return (
      <Card>
        <DataTable
          mainButton="Export"
          mainButtonOnClick={ () => {
            const st = cloneDeep(this.state);
            delete st.isDataLoading;
            exportResults(st)
          } }
          isDataLoading={ isDataLoading }
          columns={ {
            'name': {
              units: 'text',
            },
            'firstname': {
              units: 'text',
            },
            'email': {
              units: 'text',
            },
            'phone': {
              units: 'text',
            },
            'course': {
              units: 'text',
            },
          } }
          rows={ results.rows }
          count={ results.count }
          currentPage={ results.currentPage }
          pages={ results.pages }
          pagination
          onTableUpdate={ this.getResults }
          itemsPerPage={ results.itemsPerPage }
          perPage={ results.perPage }
          totalItems={ results.count }
          expanded={ expandedRow }
          expandedElement={ this.renderExpandedElement(results.rows[expandedRow]?.modules) }
          onRowClick={ expandedRow => this.setState({ expandedRow }) }
        />
      </Card>
    );
  }

  render() {
    const { courses } = this.props;
    const { courseId } = this.state;

    return (
      <div className="resultsAdmin">
        <div className="resultsAdmin-filtersWrapper">
          { this.renderDropdown(courses, 'COURSE', find(courses, o => o._id === courseId)?.title) }
          { this.renderInput({
            placeholder: 'filter',
            input: {
              name: 'filter',
              onChange: this.handleFindChange,
            },
            meta: {},
            className: 'resultsAdmin-input',
          }) }
        </div>
        { this.renderDataTable() }
      </div>
    );
  }
}
