/**
 * Created by mateimisarca on 23/11/2019
 */

import qs from 'qs';
import * as request from './../lib/utils/request';
import config from './../config';

import { RESULTS } from './../constants';

function getUserResults(results) {
  return {
    types: [RESULTS.getResults, RESULTS.getResultsSuccess, RESULTS.getResultsFail],
    promise: request.post(`${ config.apiURL }/results`, results),
  };
}

function updateStep(element, id) {
  return {
    types: [RESULTS.updateStep, RESULTS.updateStepSuccess, RESULTS.updateStepFail],
    promise: request.post(`${ config.apiURL }/results/mark-as-done`, {
      element,
      id,
    }),
  };
}

function getCourseResults({ courseId }) {
  const query = qs.stringify({ courseId }, { ignoreQueryPrefix: true });
  return {
    types: [RESULTS.getCourseResults, RESULTS.getCourseResultsSuccess, RESULTS.getCourseResultsFail],
    promise: request.get(`${ config.apiURL }/results/all?${ query }`),
  };
}

export const resultsActionsFacade = {
  getCourseResults,
  getUserResults,
  updateStep,
};

