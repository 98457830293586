/**
 * Created by matei on 17/11/2019
 */

import React, { PureComponent } from 'react';

import CourseStep from '../../components/course-step';

import './curs-view.scss';
import { findIndex } from 'lodash';

export default class CursView extends PureComponent {
  render() {
    const {
      parentPath,
      push,
      course,
      result,
    } = this.props;

    let oldElem = true;
    let newElem = true;

    return (
      <div className="pt-100 pb-70 courseView">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-title">
              <h1>{ course.title }</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="why-choose-all courseView-steps">
              { course?.elements.map((step, index) => {
                let photo;
                try {
                  if (step.link === 'feedback') {
                    photo = require(`./../../assets/moduleImg/feedback.jpg`);
                  } else {
                    photo = require(`./../../assets/moduleImg/${ step.type }.jpg`);
                  }
                } catch (err) {
                  photo = step.photo;
                }

                oldElem = newElem;
                const resultElementIndex = findIndex(result.elements, o => o.courseElementId === step._id);
                newElem = !!result?.elements[resultElementIndex]?.done;

                return (
                  <CourseStep
                    key={ index }
                    title={ step.title }
                    type={ step.type }
                    content={ step.description }
                    photo={ photo }
                    icon={ step.icon }
                    disabled={ !oldElem && step.type !== 'materiale-curs' }
                    onClick={ () => push(`${ parentPath }/${ step.link }`) }
                  />
                );
              }) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
