/**
 * Created by matei on 21/11/2019
 */

import React, { PureComponent } from 'react';
import { number, func, arrayOf, object } from 'prop-types';
import cx from 'classnames';
import { findIndex } from 'lodash';

import './steps.scss';

export default class Steps extends PureComponent {
  static propTypes = {
    currentStep: number,
    onStepChange: func.isRequired,
    steps: arrayOf(object),
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStep: props.currentStep,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentStep !== this.props.currentStep) {
      this.setState({
        currentStep: nextProps.currentStep,
      });
    }
  }

  renderStep(step, index, disabled) {
    const { currentStep } = this.state;
    const { onStepChange } = this.props;

    if ((!currentStep || currentStep < 0) && currentStep !== 0) {
      return void 0;
    }

    return (
      <div
        className={cx('steps-step', {
          'steps-step--selected': currentStep === index,
          'steps-step--disabled': disabled,
        })}
        key={index}
        onClick={() => !disabled && this.setState({
            currentStep: index,
          }, () => {
            if (onStepChange) {
              onStepChange(index);
            }
          },
        )}
      >
        <div className="steps-stepWrapper">
          <div className="steps-stepNo">
            <span>{index + 1}</span>
          </div>
          <span className="steps-stepTitle">
          {step.title}
        </span>
        </div>
      </div>
    );
  }

  render() {
    const { steps, result } = this.props;

    let oldElem = true;
    let newElem = true;

    return (
      <div className="steps">
        {steps.map((step, index) => {
          oldElem = newElem;
          const resultElementIndex = findIndex(result.elements, o => o.courseElementId === step._id);
          newElem = !!result?.elements[resultElementIndex]?.done;

          return this.renderStep(step, index, !oldElem )
        })}
      </div>
    );
  }
}
