/**
 * Created by mateimisarca on 20.03.2021
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { capitalize, map, find, debounce, isEqual, filter } from 'lodash';
import { Card, Form, FormGroup, FormLabel } from 'react-bootstrap';
import Datetime from 'react-datetime';

import { adminActionsFacade as adminActions } from '../../actions/admin';
import DropdownMenu from '../../components/dropdown-menu';
import Dropdown from '../../components/dropdown';
import DataTable from '../../components/data-table';
import InputField from '../../components/inputfield';

import 'react-datetime/css/react-datetime.css';
import './subscriptions-admin-page.scss';

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      adminActions,
    ), dispatch),
  };
}

const mapStateToProps = state => ({
  courses: state.admin.courses,
  subscriptions: state.admin.subscriptions,
  series: state.admin.series,
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SubscriptionsAdminPage extends Component {
  constructor(props) {
    super(props);

    const { getSubscriptions, getCourses, getSeries } = props.actions;

    getCourses();
    getSeries({ perPage: 50 });
    getSubscriptions({ courseId: null });

    this.state = {
      pagination: {},
      courseId: null,
      find: '',
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderDropDownCell = this.renderDropDownCell.bind(this);
    this.onModuleActiveUpdate = this.onModuleActiveUpdate.bind(this);
  }

  onDropdownClick() {
  }

  onItemClick(id, value) {
    const { getSubscriptions } = this.props.actions;
    const { pagination, find } = this.state;

    this.setState({
      pagination: {},
      courseId: id,
    });

    getSubscriptions({ courseId: id, ...pagination, find });
  }

  onSeriesUpdate(subscriptionId, seriesId) {
    const { courseId, pagination, find } = this.state;
    const { assignSeries, getSubscriptions } = this.props.actions;
    assignSeries({
      subscriptionId,
      seriesId,
    }).then(() => getSubscriptions({ courseId, ...pagination, find }));
  }

  onModuleActiveUpdate(subscriptionId, modulesActive) {
    const { courseId, pagination, find } = this.state;
    const { updateModuleActive, getSubscriptions } = this.props.actions;
    updateModuleActive({
      subscriptionId,
      modulesActive,
    }).then(() => getSubscriptions({ courseId, ...pagination, find }));
  }

  handleFindChange = debounce(
    value => {
      const { pagination, courseId } = this.state;
      const { getSubscriptions } = this.props.actions;
      this.setState({ find: value });
      getSubscriptions({ courseId, ...pagination, find: value });
    },
    1000,
    { leading: false },
  );

  handleDateChange = debounce(
    (value, name) => {
      const { pagination, courseId, find, startDate, endDate } = this.state;
      const { getSubscriptions } = this.props.actions;
      this.setState({ [name]: value });
      getSubscriptions({
        courseId, ...pagination, find, startDate, endDate,
        [name]: value,
      });
    },
    1000,
    { leading: false },
  );

  getSubscriptions(pagination) {
    const { getSubscriptions } = this.props.actions;
    const { courseId, find } = this.state;

    const newPagination = Object.assign({}, this.state.pagination, pagination);
    this.setState({
      pagination: newPagination,
    });
    getSubscriptions({
      courseId,
      ...newPagination,
      find,
    });
  }

  renderDropdown(courses, placeholder, selectedCourse, disabled = false, callback) {
    return (
      <Dropdown
        className="subscriptionsAdmin-dropdown"
        style={ { marginBottom: 0 } }
        // noPadding
        label={ selectedCourse || <span style={ { color: '#898989', textTransform: 'none' } }>
            { capitalize(placeholder) }
          </span>
        }
        size="large"
        onClick={ this.onDropdownClick }
        onClickOutside={ this.onDropdownClick }
        blockButton
        position="right"
        theme="dark"
        disabled={ disabled }
      >
        <DropdownMenu
          items={ map(courses, course => ({
            id: course._id,
            name: course.title,
          })) }
          onItemClick={ callback }
        />
      </Dropdown>
    );
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
        disabled={ disabled }
      />
    );
  }

  renderSwitch(onChange, row, columnId) {
    return (
      <Form>
        <Form.Check
          type="switch"
          id={ row._id }
          label=""
          disabled={ !(!!row.seriesId) }
          checked={ !!row.modulesActive }
          onChange={ () => onChange(row._id, !(!!row.modulesActive)) }
        />
      </Form>
    );
  }

  renderDropDownCell(row, columnId) {
    const { series } = this.props;

    const items = filter(series.rows, o => isEqual(o.courseId, row.courseId));

    return this.renderDropdown(items, 'Select the series', find(items, o => o._id === row?.seriesId)?.title, items.length <= 0, this.onSeriesUpdate.bind(this, row._id));
  }

  renderDataTable() {
    const {
      subscriptions,
      actions: {
        exportSubscriptions,
      },
    } = this.props;

    return (
      <Card>
        <DataTable
          mainButton="Export"
          mainButtonOnClick={ () => exportSubscriptions(this.state) }
          columns={ {
            'nume': {
              units: 'text',
            },
            'prenume': {
              units: 'text',
            },
            'email': {
              units: 'text',
            },
            'telefon': {
              units: 'text',
            },
            'series': {
              units: 'custom',
              customCell: this.renderDropDownCell,
            },
            'moduleActive': {
              units: 'custom',
              customCell: this.renderSwitch.bind(this, this.onModuleActiveUpdate),
            },
            'cursuri': {
              units: 'text',
            },
            'created': {
              units: 'date',
            },
          } }
          rows={ subscriptions.rows }
          count={ subscriptions.count }
          currentPage={ subscriptions.currentPage }
          pages={ subscriptions.pages }
          pagination
          onTableUpdate={ this.getSubscriptions }
          itemsPerPage={ subscriptions.itemsPerPage }
          perPage={ subscriptions.perPage }
          totalItems={ subscriptions.count }
          // onRowClick={ (id, row) => goTo(`${ page }/${ id }`) }
        />
      </Card>
    );
  }

  renderDatePicker(label, onChange) {
    return (
      <FormGroup>
        <FormLabel>{ label }</FormLabel>
        <Datetime
          dateFormat="DD-MM-YYYY"
          closeOnSelect
          timeFormat={ false }
          onChange={ onChange }
        />
      </FormGroup>
    );
  }

  render() {
    const { courses } = this.props;
    const { courseId } = this.state;

    return (
      <div className="subscriptionsAdmin">
        <div className="subscriptionsAdmin-filtersWrapper">
          { this.renderDropdown(courses, 'COURSE', find(courses, o => o._id === courseId)?.title, false, this.onItemClick) }
          { this.renderDatePicker('Data inceput', date => this.handleDateChange(date.format('YYYY-MM-DD HH:mm'), 'startDate')) }
          { this.renderDatePicker('Data sfârșit', date => this.handleDateChange(date.format('YYYY-MM-DD HH:mm'), 'endDate')) }
          { this.renderInput({
            placeholder: 'filter',
            input: {
              name: 'filter',
              onChange: this.handleFindChange,
            },
            meta: {},
            className: 'subscriptionsAdmin-input',
          }) }
        </div>
        { this.renderDataTable() }
      </div>
    );
  }
}
