/**
 * Created by mateimisarca on 23/11/2019
 */

import { find } from 'lodash';

import { COURSES } from './../constants';
import elements from '../pages/curs/elements';

const initialState = {
  status: null,
  course: null,
  modules: null,
  series: null,
};

export default function courses(state = initialState, action) {
  switch (action.type) {
    case COURSES.getCourse:
      return {
        ...state,
        status: 'loading',
      };

    case COURSES.getCourseSuccess:
      return {
        ...state,
        status: 'success',
        course: action.payload.course,
      };

    case COURSES.getCourseFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case COURSES.getSeries:
      return {
        ...state,
        status: 'loading',
      };

    case COURSES.getSeriesSuccess:
      return {
        ...state,
        status: 'success',
        series: action.payload.result,
      };

    case COURSES.getSeriesFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case COURSES.getModules:
      return {
        ...state,
        status: 'loading',
      };

    case COURSES.getModulesSuccess:
      const {
        modules,
      } = action.payload;

      return {
        ...state,
        status: 'success',
        modules: modules.map(module => ({
          ...module,
          elements: module.elements.map(element => {
            const elementItems = find(elements, o => o.type === element.type);

            return {
              ...elementItems,
              ...element,
            };
          }),
        })),
      };

    case COURSES.getModulesFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    default:
      return state;
  }
}
