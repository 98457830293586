/**
 * Created by mateimisarca on 24/07/2018
 */

import * as request  from './../lib/utils/request';
import config        from './../config';
import { CONTACT } from './../constants';

function addMessage(values) {
  return {
    types: [CONTACT.addContact, CONTACT.addContactSuccess, CONTACT.addContactFail],
    promise: request.post(`${config.apiURL}/message`, values),
  };
}

export const contactActionsFacade = {
  addMessage,
};

