import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './reset-password-success-page.scss';

export default class ResetPasswordSuccessPage extends Component {
  render() {
    return (
      <div className="registerSuccess error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>Felicitări</h1>
                <h2>Noua parolă a fost setată cu success</h2>
                <div className="registerSuccess-icon fa fa-check-circle" />
                <Link to="/">Inapoi la site</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
