/**
 * Created by mateimisarca on 24/07/2018
 */

import { createStore as _createStore, applyMiddleware, compose } from 'redux';
// import { routerMiddleware }                             from 'react-router-redux';
import { routerMiddleware }                                      from 'connected-react-router';
import ReduxThunk                                                from 'redux-thunk';
import { createBrowserHistory }                                  from 'history';
import createMiddleware                                          from './middleware/client-middleware';
// import anchors          from './middleware/anchors';
import reportPageView                                            from './middleware/analytics';
import reducer                                                   from './../reducers';

export const history = createBrowserHistory();

export default function createStore(preloadedState) {

  const middleware = [
    routerMiddleware(history),
    createMiddleware(),
    // anchors(),
    reportPageView,
    ReduxThunk,
  ];
  const store = _createStore(
    reducer(history),
    preloadedState,
    compose(applyMiddleware(...middleware)),
  );

  return store;
}
