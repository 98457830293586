/**
 * Created by mateimisarca on 24/07/2018
 */

import keyMirror from './../lib/utils/keymirror';

export const ERROR = keyMirror('error', {
  error: null,
  close: null,
});

export const LOADING_OVERLAY = keyMirror('loadingOverlay', {
  showLoadingScreen: null,
});

export const INSCRIERE = keyMirror('inscriere', {
  judete: null,
  judeteSuccess: null,
  judeteFail: null,
  getlocalitateSuperioara: null,
  getlocalitateSuperioaraSuccess: null,
  getlocalitateSuperioaraFail: null,
  getlocalitate: null,
  getlocalitateSuccess: null,
  getlocalitateFail: null,
  getUnitatiInvatamant: null,
  getUnitatiInvatamantSuccess: null,
  getUnitatiInvatamantFail: null,
  getlocalitateSuperioara2: null,
  getlocalitateSuperioaraSuccess2: null,
  getlocalitateSuperioaraFail2: null,
  getlocalitate2: null,
  getlocalitateSuccess2: null,
  getlocalitateFail2: null,
  getUnitatiInvatamant2: null,
  getUnitatiInvatamantSuccess2: null,
  getUnitatiInvatamantFail2: null,
  addInscriere: null,
  addInscriereSuccess: null,
  addInscriereFail: null,
});

export const CONTACT = keyMirror('contact', {
  addContact: null,
  addContactSuccess: null,
  addContactFail: null,
});

export const ADMIN = keyMirror('admin', {
  getSubscriptions: null,
  getSubscriptionsSuccess: null,
  getSubscriptionsFail: null,
  getCourses: null,
  getCoursesSuccess: null,
  getCoursesFail: null,
  getResults: null,
  getResultsSuccess: null,
  getResultsFail: null,
  getSeries: null,
  getSeriesSuccess: null,
  getSeriesFail: null,
  createSeries: null,
  createSeriesSuccess: null,
  createSeriesFail: null,
  assignSeries: null,
  assignSeriesSuccess: null,
  assignSeriesFail: null,
  moduleActive: null,
  moduleActiveSuccess: null,
  moduleActiveFail: null,
  exportExcel: null,
  exportExcelSuccess: null,
  exportExcelFail: null,
  exportResultsExcel: null,
  exportResultsExcelSuccess: null,
  exportResultsExcelFail: null,
});

export const AUTH = keyMirror('auth', {
  login: null,
  loginSuccess: null,
  loginFail: null,
  register: null,
  registerSuccess: null,
  registerFail: null,
  getUserProfile: null,
  getUserProfileSuccess: null,
  getUserProfileFail: null,
  verifyToken: null,
  verifyTokenSuccess: null,
  verifyTokenFail: null,
  resetUserPassword: null,
  resetUserPasswordSuccess: null,
  resetUserPasswordFail: null,
  resetUserPasswordMail: null,
  resetUserPasswordMailSuccess: null,
  resetUserPasswordMailFail: null,
  setUserProfile: null,
  setUserProfileSuccess: null,
  setUserProfileFail: null,
  logout: null,
});

export const COURSES = keyMirror('courses', {
  getCourse: null,
  getCourseSuccess: null,
  getCourseFail: null,
  getModules: null,
  getModulesSuccess: null,
  getModulesFail: null,
  getSeries: null,
  getSeriesSuccess: null,
  getSeriesFail: null,
});

export const RESULTS = keyMirror('courses', {
  getResults: null,
  getResultsSuccess: null,
  getResultsFail: null,
  updateStep: null,
  updateStepSuccess: null,
  updateStepFail: null,
  getCourseResults: null,
  getCourseResultsSuccess: null,
  getCourseResultsFail: null,
});

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
