/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { upperCase, capitalize } from 'lodash';

import slideCursDictie from '../../assets/images/slide-curs-dictie.jpg';
import slideCursResurseDigitale from '../../assets/images/slide-curs-resurse-digitale.jpg';
import slideCursAdaptare from '../../assets/images/slide-curs-adaptare.png';

const slides = [
  {
    title: 'Adaptarea activităților online la stilul de învățare al școlarilor mici.',
    text: 'Curs online gratuit pentru educatori, care ofera metode și tehnici de adaptare a activităților online la stilurile de învățare ale elevilor.',
    image: slideCursAdaptare,
    buttons: [
      {
        link: '/cursuri/adaptarea-activitatilor-online',
        text: 'Afla mai multe',
      },
      {
        link: '/inscriere/adaptarea-activitatilor-online',
        text: 'Inscrie-te',
      },
    ],
  },
  {
    title: 'DICȚIE ȘI MIȘCARE SCENICĂ PENTRU DEZVOLTAREA PERSONALĂ A COPIILOR',
    text: 'Curs online gratuit pentru educatori, care ofera metode și tehnici de formare și dezvoltare armonioasă a preșcolarilor.',
    image: slideCursDictie,
    buttons: [
      {
        link: '/cursuri/curs-stiluri-de-invatare',
        text: 'Afla mai multe',
      },
      {
        link: '/inscriere/curs-stiluri-de-invatare',
        text: 'Inscrie-te',
      },
    ],
  },
  {
    title: 'Utilizarea responsabilă a resurselor educaționale digitale',
    text: 'Curs online pentru profesorii de gimnaziu, în care aceștia vor afla tot ce trebuie să știe despre manualele digitale, precum și informații despre instrumente digitale moderne pentru desfășurarea activităților extracurriculare.',
    image: slideCursResurseDigitale,
    buttons: [
      {
        link: '/cursuri/curs-resurse-digitale-pentru-profesori',
        text: 'Afla mai multe',
      },
      {
        link: '/inscriere/curs-resurse-digitale-pentru-profesori',
        text: 'Inscrie-te',
      },
    ],
  },
];

export default class Slider extends Component {
  componentDidMount() {
    /* eslint-disable */
    $('.all-slide').owlCarousel({
      items: 1,
      nav: true,
      dots: true,
      autoplay: true,
      loop: true,
      navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
      mouseDrag: false,
      touchDrag: false,
    });

    $('.all-slide').on('translate.owl.carousel', function () {
      $('.slider-text h1').removeClass('animated fadeInUp').css('opacity', '0');
      $('.slider-text p').removeClass('animated fadeInDown').css('opacity', '0');
      $('.slider-text ul').removeClass('animated fadeInDown').css('opacity', '0');
    });

    $('.all-slide').on('translated.owl.carousel', function () {
      $('.slider-text h1').addClass('animated fadeInUp').css('opacity', '1');
      $('.slider-text p').addClass('animated fadeInDown').css('opacity', '1');
      $('.slider-text ul').addClass('animated fadeInDown').css('opacity', '1');
    });
    /* eslint-enable */
  }

  render() {
    return (
      <div className="slider">
        <div className="all-slide owl-item">
          {slides.map((slide, index) => (
            <div className="single-slide" style={{backgroundImage: `url(${slide.image})`}} key={index}>
              <div className="slider-overlay" />
              <div className="slider-wrapper">
                <div className="slider-text">
                  <div className="slider-caption">
                    <h1>{upperCase(slide.title)}</h1>
                    <p>{slide.text}</p>
                    <ul>
                      {slide.buttons.map((button, btnIndex) => (
                        <li key={btnIndex}>
                          <Link to={button.link}>{capitalize(button.text)}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
