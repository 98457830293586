/**
 * Created by matei.misarca on 20/07/16.
 */

import { required, email, phoneNumber } from './../../lib/utils/validations';

const inscriereValidation = (data) => {
  return {
    title: [required],
    course: [required],
    emailText: [required],

  };
};

export default inscriereValidation;
