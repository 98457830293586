import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './inscriere-success-page.scss';
import { includes } from 'lodash';

export default class InscriereSuccessPage extends Component {
  render() {
    console.log('@@@- ', this.props);

    let message = 'Inscrierea a fost efectuata cu success';
    if (includes(this.props.location?.search, 'adaptarea-activitatilor-online')) {
      let message = ``;
    }

    return (
      <div className="inscriereSuccess error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>Felicitari</h1>
                { !includes(this.props.location?.search, 'adaptarea-activitatilor-online') ?
                  (<h2>Inscrierea a fost efectuata cu success</h2>) :
                  (<div>
                    <h2>Felicitări! Înscrierea dumneavoastră la cursul online gratuit “Adaptarea activităților online la
                      stilul de învățare al școlarilor mici” a fost înregistrată cu succes!</h2>
                    <h3>În curând veți primi pe email repartizarea pentru întâlnirile sincron.</h3>
                    <h4>Mult succes!</h4>
                  </div>)
                }
                <div className="inscriereSuccess-icon fa fa-check-circle" />
                <Link to="/">Inapoi la site</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
