/**
 * Created by mateimisarca on 2019-06-14
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './in-constructie.scss';

export default class InConstructie extends Component {
  render() {
    return (
      <div className="inConstructie">
        <h1>Informații disponibile în curând</h1>

      </div>
    );
  }
}
