/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';

import Slider from './../../components/slider';
import HpAbout from './../../components/hp-about';
import Courses from './../../components/courses';
import Testimonials from './../../components/testimonials';

export default class HomePage extends Component {
    render() {
        return (
            <div>
                <Slider />
                {/*<HpAbout />*/}

                <Courses />
                <Testimonials />
            </div>
        )
    }
}
