/**
 * Created by matei on 14/11/2019
 */

import { AUTH } from './../constants';
import { addToken } from './../lib/utils/request';

const initialState = {
  status: null,
  jwtToken: null,
  isLoggedIn: null,
  error: null,
  user: null,
};

export default function inscriere(state = initialState, action) {
  switch (action.type) {
    case AUTH.logout:
      localStorage.removeItem('jwtToken');
      addToken('');

      return initialState;

    case AUTH.login:
      return {
        ...state,
        status: 'loading',
      };

    case AUTH.loginSuccess:
      const jwtToken = `Bearer ${ action.payload.token }`;

      localStorage.setItem('jwtToken', jwtToken);
      addToken(jwtToken);

      return {
        ...state,
        status: 'success',
        jwtToken,
        isLoggedIn: true,
      };

    case AUTH.loginFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case AUTH.getUserProfile:
      return {
        ...state,
        status: 'loading',
      };

    case AUTH.getUserProfileSuccess:
      return {
        ...state,
        status: 'success',
        user: action.payload.user,
        isLoggedIn: true,
      };

    case AUTH.getUserProfileFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    default:
      return state;
  }
}
