/**
 * Created by mateimisarca on 30/12/2019
 */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import InputField from '../../components/inputfield';
import Heading from '../../components/heading';

import getValidator from '../../lib/utils/validation';
import passwordResetValidation from './password-reset.validation';

import { authActionsFacade as authActions } from '../../actions/auth';

const submit = (push, values, dispatch) => {
  /*
    event({
      category: 'Courses',
      action: 'Course registration',
      label: values.cursuri,
    });
  */
  dispatch(authActions.resetUserPasswordMail({
    ...values,
  })).then((res) => {
    if (res.payload?.success) {
      push('/password-reset/success');
    }
  });
};

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: `/password-reset`,
    text: `Reset Password`,
  },
];

@withRouter
@reduxForm({
  form: 'passwordResetForm',
  asyncValidate: getValidator(passwordResetValidation),
})
export default class PasswordReset extends PureComponent {
  constructor() {
    super();

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      history: {
        push,
      },
    } = this.props;

    return (
      <div className="loginPage">
        <Heading title="Resetare Parolă" links={ headingLinks } />

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>Resetare Parolă</h2>
                <p>Introduceți-vă adresa de e-mail</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push)) }>
                  <div className="row">

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <Field
                          name="email"
                          type="email"
                          component={ this.renderInput }
                          placeholder="E-Mail"
                          className="form-element"
                        />
                      </div>
                    </div>

                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Trimite" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
