/**
 * Created by matei.misarca on 20/07/16.
 */

import { required, email, phoneNumber } from './../../lib/utils/validations';

const inscriereValidation = (data) => {
  return {
    email: [required, email],
    nume: [required],
    prenume: [required],
    telefon: [required, phoneNumber],
    judet: [required],
    localitate_superioara: [required],
    localitate: [required],
    unitate_invatamant: [required],
    adresa: [required],
    // nivel: [required],
    // functie: [required],
    materie: [required],
    // clasa: [required],
    cursuri: data.materiale_educationale ? [] : [required],
    materiale_educationale: data.cursuri ? [] : [required],
    // diriginte: [required],
    termeni_conditii: [required],
  };
};

export default inscriereValidation;
