/**
 * Created by mateimisarca on 2019-06-27
 */

import React from 'react';
import { Link } from 'react-router-dom';

export function getFields(path, school2) {
  const newField = [...fields, ...schoolField];
  if (school2) {
    const schoolNew = schoolField.map(field => ({
      ...field,
      name: field.name + '2',
      variable: field.variable + '2',
    }));
    newField.push(...schoolNew);
  }

  if (path === '/inscriere-materiale-educationale') {
    return [...newField, ...positionFields, materieField, ...teacher, materialeEducationaleField, TC];
  }

  if (path === '/inscriere-limbi-straine') {
    return [...newField, materieField, cursField, TC];
  }

  if (path === '/inscriere-materiale-educationale-limbi-straine') {
    return [...newField, materieField, materialeEducationaleField, TC];
  }

  return [...newField, ...positionFields, materieField, ...teacher, cursField, TC];
}

export const fields = [
  {
    field: 'input',
    placeholder: 'Nume',
    type: 'text',
    name: 'nume',
    disabled: true,
  },
  {
    field: 'input',
    placeholder: 'Prenume',
    type: 'text',
    name: 'prenume',
    disabled: true,
  },
  {
    field: 'input',
    placeholder: 'Telefon',
    type: 'text',
    name: 'telefon',
    disabled: true,
  },
  {
    field: 'input',
    placeholder: 'E-mail',
    type: 'text',
    name: 'email',
    disabled: true,
  },
];

const schoolField = [
  {
    field: 'select',
    placeholder: 'Judet',
    type: 'text',
    name: 'judet',
    variable: 'judete',
    singleLine: true,
  },
  {
    field: 'select',
    placeholder: 'Localitate superioara',
    type: 'text',
    name: 'localitate_superioara',
    variable: 'localitatiSuperioare',
    singleLine: true,
  },
  {
    field: 'select',
    placeholder: 'Localitate',
    type: 'text',
    name: 'localitate',
    variable: 'localitati',
    singleLine: true,
  },
  {
    field: 'select',
    placeholder: 'Unitatea de învățământ',
    type: 'text',
    name: 'unitate_invatamant',
    variable: 'unitatatiInvatamant',
    singleLine: true,
  },
  {
    field: 'input',
    placeholder: 'Adresa scoala',
    type: 'text',
    name: 'adresa',
    singleLine: true,
  },
];

const positionFields = [
  {
    field: 'select-list',
    placeholder: 'Nivel',
    type: 'text',
    name: 'nivel',
    variable: 'nivel',
  },
  {
    field: 'select-list',
    placeholder: 'Funcție',
    type: 'text',
    name: 'functie',
    variable: 'functie',
  },
];

const materieField = {
  field: 'select-list',
  placeholder: 'Materie',
  type: 'text',
  name: 'materie',
  variable: 'materie',
}

const teacher = [
  {
    field: 'select-list',
    placeholder: 'Clasa',
    type: 'text',
    name: 'clasa',
    variable: 'clasa',
  },
  {
    field: 'select',
    placeholder: 'Sunt diriginte',
    type: 'text',
    name: 'diriginte',
    variable: 'diriginte',
  },
];

const cursField = {
  field: 'select',
  placeholder: 'Curs',
  type: 'text',
  name: 'cursuri',
  variable: 'cursuri',
};

const materialeEducationaleField = {
  field: 'checkbox',
  placeholder: (<span>Sunt de acord sa primesc gratuit pachetul cu materiale educationale.</span>),
  type: 'checkbox',
  name: 'materiale_educationale',
  variable: 'materiale_educationale',
};

const TC = {
  field: 'checkbox',
  placeholder: (<span>Sunt de acord cu <Link to="/termeni-si-conditii" onClick={e => e.stopPropagation()}>termenii si conditiile</Link> site-ului.</span>),
  type: 'checkbox',
  name: 'termeni_conditii',
  variable: 'termeni_conditii',
};
