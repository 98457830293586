/**
 * Created by mateimisarca on 2019-06-11
 */

import React, { Component } from 'react';
import { find } from 'lodash';
import { reduxForm, Field, formValueSelector, change as changeFieldValue } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize, includes, remove } from 'lodash';
import { withRouter } from 'react-router-dom';
import { event } from 'react-ga';
import qs from 'qs';

import Heading from '../../components/heading';
import InputField from './../../components/inputfield';
import Checkbox from './../../components/checkbox';
import Dropdown from './../../components/dropdown';
import DropdownMenu from './../../components/dropdown-menu';

import getValidator from './../../lib/utils/validation';

import inscriereValidation from './inscriere.validation';

import { LoadingScreenActionsFacade as LoadingScreenActions } from '../../actions/loading-screen';
import { inscriereActionsFacade as inscriereActions } from '../../actions/inscriere';
import { authActionsFacade as authActions } from '../../actions/auth';

import { courses } from '../../constants/courses';
import inscriereConstants from '../../constants/inscriere';
import { getFields } from './fields';

import './inscriere-page.scss';

const submit = (push, path, ui, ui2, values, dispatch) => {
  event({
    category: 'Courses',
    action: 'Course registration',
    label: values.cursuri,
  });
  dispatch(inscriereActions.addInscriere({
    ...values,
    ui_id: ui._id,
    ui_id2: ui2?._id,
    path,
  })).then(() => dispatch(authActions.getUserProfile()).then(() => push(`/inscriere/success?${ qs.stringify({ path }, { ignoreQueryPrefix: true }) }`)));
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      LoadingScreenActions,
      inscriereActions,
      changeFieldValue,
    ), dispatch),
  };
}

function mapStateToProps(state) {
  const selector = formValueSelector('inscriereForm');

  const isForeignLanguages = includes(state.router.location.pathname, 'limbi-straine');

  return {
    judete: state.inscriere.judete,
    judete2: state.inscriere.judete2,
    localitatiSuperioare: state.inscriere.localitatiSuperioare,
    localitati: state.inscriere.localitati,
    unitatatiInvatamant: state.inscriere.unitatatiInvatamant,
    localitatiSuperioare2: state.inscriere.localitatiSuperioare2,
    localitati2: state.inscriere.localitati2,
    unitatatiInvatamant2: state.inscriere.unitatatiInvatamant2,
    nivel: inscriereConstants.nivel,
    functie: inscriereConstants.functie,
    materie: isForeignLanguages ? inscriereConstants.foreignLanguages : inscriereConstants.materie,
    clasa: inscriereConstants.clasa,
    cursuri: inscriereConstants.cursuri,
    diriginte: inscriereConstants.diriginte,
    judet: selector(state, 'judet'),
    judet2: selector(state, 'judet2'),
    initialValues: {
      nume: state.auth.user.name,
      prenume: state.auth.user.firstname,
      telefon: state.auth.user.phone,
      email: state.auth.user.email,
      cursuri: state.courses?.course?.title,
    },
  };
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
  form: 'inscriereForm',
  asyncValidate: getValidator(inscriereValidation),
})
export default class InscrierePage extends Component {
  constructor(props) {
    super(props);

    const {
      params: {
        course,
      },
      path,
    } = props.match;
    const selectedCourse = find(courses, o => o.link === course);

    this.state = {
      selectedCourse,
      ui: null,
      ui2: null,
      school2: false,
      fields: getFields(path, false),
    };

    this.renderInput = this.renderInput.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  componentWillMount() {
    const { getJudete } = this.props.actions;
    getJudete();
  }

  onDropdownClick(/*input*/) {
    // input.onBlur();
  }

  onItemClick(onChange, name, id, value) {
    const {
      actions: {
        getLocalitateSuperioara,
        getLocalitate,
        getUnitatiInvatamant,
        getLocalitateSuperioara2,
        getLocalitate2,
        getUnitatiInvatamant2,
        // changeFieldValue,
      },
      dispatch,
      unitatatiInvatamant,
      unitatatiInvatamant2,
    } = this.props;

    onChange(value);

    switch (name) {
      case 'judet':
        getLocalitateSuperioara(value);
        break;

      case 'localitate_superioara':
        getLocalitate(value);
        break;

      case 'localitate':
        getUnitatiInvatamant(value, this.props.judet);
        break;

      case 'unitate_invatamant':
        const UI = find(unitatatiInvatamant, { _id: id });
        dispatch(changeFieldValue('inscriereForm', 'adresa', UI.adresa));
        this.setState({
          ui: UI,
        });
        break;
      case 'judet2':
        getLocalitateSuperioara2(value);
        break;

      case 'localitate_superioara2':
        getLocalitate2(value);
        break;

      case 'localitate2':
        getUnitatiInvatamant2(value, this.props.judet2);
        break;

      case 'unitate_invatamant2':
        const UI2 = find(unitatatiInvatamant2, { _id: id });
        dispatch(changeFieldValue('inscriereForm', 'adresa2', UI2.adresa));
        this.setState({
          ui2: UI2,
        });
        break;
      default:
        break;
    }
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
        disabled={ disabled }
      />
    );
  }

  renderDropdown(judete, { input, placeholder, meta: { touched, error } }) {
    return (
      <div>
        <Dropdown
          noPadding
          label={ input.value ||
          <span style={ { color: '#898989', textTransform: 'none' } }>{ capitalize(placeholder) }</span> }
          size="large"
          onClick={ this.onDropdownClick }
          onClickOutside={ this.onDropdownClick }
          blockButton
          position="right"
          theme="dark"
        >
          <DropdownMenu
            items={ judete || [] }
            onItemClick={ this.onItemClick.bind(this, input.onBlur, input.name) }
          />
        </Dropdown>
        { touched && error && <span className="inscriere-error">{ error }</span> }
      </div>
    );
  }

  renderCheckbox({ input, placeholder, meta: { touched, error } }) {
    return (
      <div>
        <Checkbox
          checked={ input.value }
          label={ placeholder }
          onChange={ checked => input.onBlur(checked) }
        />
        { touched && error && <span className="inscriere-error">{ error }</span> }
      </div>
    );
  }

  onCheckboxListChange(element, onChange, value, checked) {
    if (checked) {
      if (typeof value === 'string') {
        onChange([element.id]);
      } else {
        const newElement = value;
        newElement.push(element.id);
        onChange(newElement);
      }
    } else {
      const newElement = remove(value, e => e !== element.id);

      onChange(newElement);
    }
  }

  renderCheckboxList(list, { input, placeholder, meta: { touched, error } }) {
    return (
      <div>
        { list.map((element, index) => {
          return (
            <div key={ element.id } className="col-md-6 col-sm-6 col-xs-12">
              <Checkbox
                key={ `${ element.id }_${ index }` }
                checked={ includes(input.value, element.id) }
                label={ element.name }
                onChange={ this.onCheckboxListChange.bind(this, element, input.onChange, input.value) }
              />
            </div>
          );
        }) }
        { touched && error && <span className="inscriere-error">{ error }</span> }
      </div>
    );
  }

  render() {
    const { fields } = this.state;
    const {
      handleSubmit,
      history: {
        push,
      },
      match: {
        url,
      },
    } = this.props;
    const path = url;

    const headingLinks = [
      {
        link: '/',
        text: 'Acasa',
      },
      {
        link: `/inscriere`,
        text: `Înscriere`,
      },
    ];

    return (
      <div className="inscriere-page">
        <Heading
          title={ `Înscriere ${ includes(path, '/inscriere-materiale-educationale') ? 'pachete gratuite cu materiale educaționale' : '' }` }
          links={ headingLinks } />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>{ `Înscriere ${ includes(path, '/inscriere-materiale-educationale') ? 'pachete gratuite cu materiale educaționale' : '' }` }</h2>
                <p>{ `Introduceți datele dumnneavoastră în formularul de mai jos, pentru a ${ includes(path, '/inscriere-materiale-educationale') ? 'primi pachetul gratuit' : 'vă înscrie la cursul online dorit' }` }</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push, path, this.state.ui, this.state.ui2)) }>
                  <div className="row">

                    { fields.map((field, index) => {
                      const list = field.variable ? this.props[field.variable] : null;
                      const className = field.singleLine || field.field === 'select-list' || field.field === 'checkbox' ?
                        'col-md-12 col-sm-12 col-xs-12 pl-0' : 'col-md-6 col-sm-6 col-xs-12';

                      return (
                        <div className={ className } key={ index }>
                          <div className="single-input-field">
                            { field.field === 'input' ? (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderInput }
                                placeholder={ field.placeholder }
                                className="form-element"
                                disabled={ field.disabled }
                              />
                            ) : field.field === 'checkbox' ? (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderCheckbox }
                                className="form-element"
                                placeholder={ field.placeholder }
                              />
                            ) : field.field === 'select-list' ? (
                              <div>
                                <h4 style={ { marginTop: '20px' } }>{ `${ field.placeholder }:` }</h4>
                                <Field
                                  name={ field.name }
                                  type={ field.type }
                                  component={ this.renderCheckboxList.bind(this, list) }
                                  placeholder={ field.placeholder }
                                  className="form-element"
                                />
                              </div>
                            ) : (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderDropdown.bind(this, list) }
                                placeholder={ field.placeholder }
                                className="form-element"
                              />
                            ) }
                          </div>
                          { field.name === 'adresa' && !this.state.school2 && (
                            <div className="inscriere-addSchool" onClick={ () => {
                              this.setState({
                                school2: true,
                                fields: getFields(path, true),
                              });
                            } }>
                              <div className="inscriere-addSchoolIcon fa fa-plus-circle" />
                              Adauga o noua scoala
                            </div>
                          ) }
                        </div>
                      );
                    }) }

                  </div>
                  <div className="">
                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Trimite" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

