/**
 * Created by mateimisarca on 24/09/2020
 */

const utcToLocal = date => {
  const d = new Date(date);
  const newDate = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
  const offset = d.getTimezoneOffset() / 60;
  const hours = d.getHours();

  newDate.setHours(hours - offset);
  return newDate;
};

export default utcToLocal;
