/**
 * Created by mateimisarca on 2019-06-12
 */

import React, { Component } from 'react';
import { join } from 'lodash';

import teacher from './../../assets/images/teacher_1.jpg';
import photoIonela from './../../assets/images/ionela_stan.jpg';
import photoAndreea from './../../assets/images/andreea_ciocilteu.jpg';
import photoCristina from './../../assets/images/cristina_gunea.jpg';
import photoMadalina from './../../assets/images/madalina_radu.jpg';
import photoSilvia from './../../assets/images/silvia_borteanu.jpg';
import photoIoana from './../../assets/images/ioana_cosma.jpg';
import photoAdina from './../../assets/images/adina_tatu.jpg';

import './traineri-page.scss';
import Heading from '../../components/heading';
import banner from '../../assets/images/banner_1920x350-2.jpg';

const trainers = [
  {
    name: 'Ionela Stan',
    details: [
      'Psihopedagog',
      'coaching',
      'formator',
      'autor',
    ],
    photo: photoIonela,
  },
  {
    name: 'Cristina Gunea',
    details: [
      'Psiholog', 'consilier școlar', 'autor de manuale', 'formator pentru educația adulților',
    ],
    photo: photoCristina,
  },
  {
    name: 'Andreea Ciocâlteu',
    details: [
      'Consilier școlar', 'psiholog', 'autor de manuale',
    ],
    photo: photoAndreea,
  },
  {
    name: 'Mădălina Radu',
    details: [
      'Consilier școlar în cadrul CMBRAE/CMBAP', 'autor manuale și auxiliare școlare',
    ],
    photo: photoMadalina,
  },
  {
    name: 'Silvia Borțeanu',
    details: [
      'profesor metodist', 'formator', 'autor programe, auxiliare școlare, ghiduri pentru cadre didactice',
    ],
    photo: photoSilvia,
  },
  {
    name: 'Adina Tatu',
    details: [
      'profesor metodist', 'consilier școlar', 'formator pentru educația adulților', 'mentor',
    ],
    photo: photoAdina,
  },
  {
    name: 'Ioana Cosma',
    details: [
      'profesor metodist', 'coordonator aria curriculară informatică și TIC', 'expert management educational',
    ],
    photo: photoIoana,
  },
];

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/traineri',
    text: 'Formatori',
  },
];

export default class TraineriPage extends Component {
  render() {
    return (
      <div>
        <Heading title="Formatori" links={ headingLinks } image={ banner } />

        <div className="traineri instructor-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              { trainers.map((trainer, index) => (

                <div className="col-md-3 col-sm-6 col-xs-12" key={ index }>
                  <div className="instructor-member">
                    <div className="instructor-member-thumb">
                      <img src={ trainer.photo } alt="" />
                      <div className="instructor-overlay">
                        <ul>
                          { join(trainer.details, ', ') }
                        </ul>
                      </div>
                    </div>
                    <h2>{ trainer.name }</h2>
                  </div>
                </div>
              )) }

            </div>
          </div>
        </div>
      </div>
    );
  }
}
