/**
 * Created by mateimisarca on 24/07/2018
 */


import { LOADING_OVERLAY, LOCATION_CHANGE } from './../constants';

const initialState = true;

export default function loadingScreen(state = initialState, action) {
  const {payload} = action;

  switch (action.type) {
    case LOADING_OVERLAY.showLoadingScreen:
      return payload;

    case LOCATION_CHANGE:
      if (action.locationBeforeTransitions && payload.pathname !== action.locationBeforeTransitions.pathname) {
        return true;
      }
      return state;


    default:
      return state;
  }
}

