/**
 * Created by mateimisarca on 2019-06-12
 */

import { kebabCase } from 'lodash';

const nivel = [
  'Prescolar',
  'Primar',
  'Gimanzial',
  'Liceal',
  'Profesional',
  'Postliceal',
];

const functie = [
  'Bibliotecar',
  'Contabil',
  'Director',
  'Director Adjunct',
  'Educator',
  'Inspector Scolar',
  'Invatator',
  'Profesor',
  'Secretar',
  'Sef de catedra',
  'Sef de comisie metodica',
];

const materie = [
  'Alta materie',
  'Biologie',
  'Chimie',
  'Consiliere si dezvoltare personala',
  'Educatie fizica si sport',
  'Educatie muzicala',
  'Educatie plastica',
  'Educatie sociala',
  'Educatie Speciala',
  'Educatie tehnologica si aplicatii practice',
  'Educator',
  'Engleză',
  'Engleză intensiv',
  'Fizica',
  'Franceza',
  'Geografie',
  'Germana',
  'Greaca',
  'Informatica si TIC',
  'Invatator',
  'Istorie',
  'Italiana',
  'Itinerant',
  'Latina',
  'Limba si literatura romana',
  'Logica',
  'Logopedie',
  'Maghiara',
  'Matematica',
  'Religie',
  'Rusa',
  'Turca',
];

const foreignLanguages = [
  'Engleză L1',
  'Engleza L2',
  'Engleză Intensiv',
  'Franceza L1',
  'Franceză L2',
  'Franceză Intensiv',
]

const clasa = [
  'Grupa Mica',
  'Grupa Mijlocie',
  'Grupa Mare',
  'Clasa Pregatitoare',
  'Clasa 1',
  'Clasa a 2-a',
  'Clasa a 3-a',
  'Clasa a 4-a',
  'Clasa a 5-a',
  'Clasa a 6-a',
  'Clasa a 7-a',
  'Clasa a 8-a',
  'Clasa a 9-a',
  'Clasa a 10-a',
  'Clasa a 11-a',
  'Clasa a 12-a',
  'Clase nivel postliceal',
];

const cursuri = [
  'Dicție și mișcare scenică pentru dezvoltarea personală a copiilor. Curs pentru educatori',
  'Resurse digitale pentru profesorii de gimnaziu',
  'Provocări și soluții în educația digitală',
  'Adaptarea activităților online la stilul de învățare al școlarilor mici'
];

const diriginte = [
  'DA',
  'NU',
];

const getDropdownFormat = arr => arr.map(element => ({
  name: element,
  id: kebabCase(element),
}));

export default {
  nivel: getDropdownFormat(nivel),
  functie: getDropdownFormat(functie),
  materie: getDropdownFormat(materie),
  foreignLanguages: getDropdownFormat(foreignLanguages),
  clasa: getDropdownFormat(clasa),
  cursuri: getDropdownFormat(cursuri),
  diriginte: getDropdownFormat(diriginte),
};

