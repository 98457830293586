/**
 * Created by mateimisarca on 2019-06-12
 */

const NODE_ENV = process.env.NODE_ENV;
console.log('NODE_ENV ', NODE_ENV);

const config = {
  // apiURL: NODE_ENV === 'development' ? 'http://localhost:3060' : '//cursuridigitale.ro/api',
  // apiURL: '//185.131.222.141:33344',
  apiURL: 'https://cursuridigitale.ro/api',
  imagesURL: '//cursuridigitale.ro/images'
};

export default config;

