/**
 * Created by mateimisarca on 22.03.2021
 */

// Globals
export const DEFAULT_CURRENCY = 'USD';
export const currencyMap = [
  ['$', 'USD'],
  ['€', 'EUR'],
  ['£', 'GBP'],
  ['¥', 'YUAN'],
];

