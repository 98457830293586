/**
 * Created by mateimisarca on 2019-06-12
 */

import { kebabCase } from 'lodash';

import { INSCRIERE } from './../constants';

const initialState = {
  status: null,
  judete: null,
  localitatiSuperioare: null,
  localitati: null,
  unitatatiInvatamant: null,
};

export default function inscriere(state = initialState, action) {
  switch (action.type) {
    case INSCRIERE.judete:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.judeteSuccess:
      return {
        ...state,
        status: 'success',
        judete: action.payload.judete.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
        judete2: action.payload.judete.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
      };

    case INSCRIERE.judeteFail:
      return {
        ...state,
        status: 'error',
        judete: null,
      };

    case INSCRIERE.getlocalitateSuperioara:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getlocalitateSuperioaraSuccess:
      return {
        ...state,
        status: 'success',
        localitatiSuperioare: action.payload.localitatiSuperioare.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
      };

    case INSCRIERE.getlocalitateSuperioaraFail:
      return {
        ...state,
        status: 'error',
        localitatiSuperioare: null,
      };

    case INSCRIERE.getlocalitate:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getlocalitateSuccess:
      return {
        ...state,
        status: 'success',
        localitati: action.payload.localitati.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
      };

    case INSCRIERE.getlocalitateFail:
      return {
        ...state,
        status: 'error',
        localitati: null,
      };

    case INSCRIERE.getUnitatiInvatamant:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getUnitatiInvatamantSuccess:
      return {
        ...state,
        status: 'success',
        unitatatiInvatamant: action.payload.unitatatiInvatamant.map(unitate => ({
          name: unitate.unitateInvatamant,
          id: unitate._id,
          ...unitate,
        })),
      };

    case INSCRIERE.getUnitatiInvatamantFail:
      return {
        ...state,
        status: 'error',
        unitatatiInvatamant: null,
      };

    case INSCRIERE.getlocalitateSuperioara2:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getlocalitateSuperioaraSuccess2:
      return {
        ...state,
        status: 'success',
        localitatiSuperioare2: action.payload.localitatiSuperioare.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
      };

    case INSCRIERE.getlocalitateSuperioaraFail2:
      return {
        ...state,
        status: 'error',
        localitatiSuperioare2: null,
      };

    case INSCRIERE.getlocalitate2:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getlocalitateSuccess2:
      return {
        ...state,
        status: 'success',
        localitati2: action.payload.localitati.map(judet => ({
          name: judet,
          id: kebabCase(judet),
        })),
      };

    case INSCRIERE.getlocalitateFail2:
      return {
        ...state,
        status: 'error',
        localitati2: null,
      };

    case INSCRIERE.getUnitatiInvatamant2:
      return {
        ...state,
        status: 'loading',
      };

    case INSCRIERE.getUnitatiInvatamantSuccess2:
      return {
        ...state,
        status: 'success',
        unitatatiInvatamant2: action.payload.unitatatiInvatamant.map(unitate => ({
          name: unitate.unitateInvatamant,
          id: unitate._id,
          ...unitate,
        })),
      };

    case INSCRIERE.getUnitatiInvatamantFail2:
      return {
        ...state,
        status: 'error',
        unitatatiInvatamant2: null,
      };

    default:
      return state;
  }
}
