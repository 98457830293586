/**
 * Created by matei.misarca on 20/07/16.
 */

import { required, email, phoneNumber } from './../../lib/utils/validations';

const contactValidation = (data) => {
  return {
    email: [required, email],
    nume: [required],
    prenume: [required],
    telefon: [required, phoneNumber],
    mesaj: [required],

  };
};

export default contactValidation;
