/**
 * Created by mateimisarca on 29/11/2019
 */

import { required, maxLength } from './../../../lib/utils/validations';

const aplicatieValidation = (data) => {
  return {
    app: [required/*, maxLength(750)*/],
  };
};

export default aplicatieValidation;

