/**
 * Created by mateimisarca on 05/03/2018
 */

import Textarea from 'react-textarea-autosize';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import cx from 'classnames';
import Geosuggest from 'react-geosuggest';

import './inputfield.scss';

class InputField extends Component {

  static propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    debounceTimeout: PropTypes.number,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    formSubmitStatus: PropTypes.string,
    icon: PropTypes.element,
    iconRemove: PropTypes.element,
    iconLeft: PropTypes.element,
    iconClick: PropTypes.func,
    iconRemoveClick: PropTypes.func,
    iconLeftClick: PropTypes.func,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    numericOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    multiline: PropTypes.bool,
    touched: PropTypes.bool,
    geosuggest: PropTypes.bool,
    geosuggestTypes: PropTypes.array,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    resizable: PropTypes.bool,
    remove: PropTypes.bool,
    required: PropTypes.bool,
    success: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    sendOnEnter: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onGeoSuggest: PropTypes.func,
  };

  static defaultProps = {
    debounceTimeout: 0,
    multiline: false,
    geosuggest: false,
    geosuggestTypes: ['address'],
    remove: false,
    placeholder: '',
    onChange: () => {
    },
    onBlur: () => {
    },
    sendOnEnter: false,
  };

  constructor(props) {
    super(props);
    this.debounceChangeCallback = debounce((func) => {
      func();
    }, this.props.debounceTimeout);

    this.state = {
      inputValue: props.value || props.defaultValue,
      isFocused: false,
    };
  }

  // react methods
  componentWillMount() {
    this.setState({
      inputValue: this.props.value,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        inputValue: nextProps.value,
      });
    }
  }

  onChange(ev) {
    const {
      max,
      numericOnly,
      multiline,
    } = this.props;
    let inputValue = ev.target.value;

    if (!this.props.disabled) {
      // validate numeric values
      if (numericOnly) {
        inputValue = !isNaN(parseFloat(inputValue)) ? parseFloat(inputValue) : '';
        inputValue = inputValue > max ? max : inputValue;
      }

      if (ev.keyCode === 13 && multiline) {
        inputValue += '\n';
      }

      this.setState({
        inputValue,
      });

      // this.debounceChangeCallback(
      this.props.onChange(inputValue, ev);
      // );
    }
  }

  onBlur(ev) {
    const {
      disabled,
      onBlur,
      min,
      numericOnly,
    } = this.props;
    let inputValue = ev.target.value;

    if (numericOnly) {
      inputValue = !isNaN(parseFloat(inputValue)) ? parseFloat(inputValue) : min;
      inputValue = inputValue < min ? min : inputValue;

      this.setState({
        inputValue,
      });
    }

    if (!disabled && onBlur) {
      onBlur(ev);
    }
  }

  onKeyDown(ev) {
    const {
      sendOnEnter,
      onChange,
      onKeyDown,
    } = this.props;
    const inputValue = ev.currentTarget.value;

    if ((ev.keyCode === 13) && (onChange) && !sendOnEnter) {
      // stop browser from sending default 'forms' on enter key
      ev.preventDefault();

      // cancel any debounced calls
      this.debounceChangeCallback.cancel();

      // direct call of onChange function
      // onChange(inputValue, ev);
      this.onChange(ev);
    } else if (onKeyDown) {
      onKeyDown(ev);
    }
  }

  handleGeoFocus() {
    this.setState({
      isFocused: true,
    });
  }

  handleGeoBlur(ev) {
    const { onChange } = this.props;
    this.setState({
      isFocused: false,
    });
    if (ev) {
      onChange(ev);
    }
  }

  handleSuggestSelect(suggestion) {
    const {
      onGeoSuggest,
    } = this.props;

    if (onGeoSuggest) {
      onGeoSuggest(suggestion);
    }
    /*
     this.setState({
     inputValue: suggestion.label
     });
     */
    this.handleGeoBlur();
  }

  renderInput(inputId) {
    /* eslint-disable no-unused-vars */
    const domOnlyProps = ({
                            initialValue,
                            autofill,
                            onUpdate,
                            valid,
                            invalid,
                            dirty,
                            pristine,
                            active,
                            touched,
                            visited,
                            autofilled,
                            numericOnly,
                            ...domProps
                          }) => domProps;
    /* eslint-enable no-unused-vars */
    const { disabled, name, numericOnly, maxLength, type, autoComplete, country } = this.props;
    let inputType;
    if (type) {
      inputType = type;
    } else {
      inputType = numericOnly ? 'number' : 'text';
    }

    const classes = cx('InputField-Input', { 'InputField-Input--hasValue': this.state.inputValue && this.state.inputValue.length > 0 });
    const geosuggestClasses = cx({
      'geosuggest--isFocused': this.state.isFocused,
      'geosuggest--hasValue': this.state.inputValue && this.state.inputValue.length > 0,
    });

    if (this.props.multiline) {
      return (
        <Textarea
          { ...domOnlyProps({ disabled, name, maxLength, type }) }
          name={ name }
          id={ inputId }
          className={ classes }
          disabled={ disabled }
          maxLength={ maxLength }
          value={ this.state.inputValue }
          onChange={ this.onChange.bind(this) }
          onBlur={ this.onBlur.bind(this) }
          onKeyDown={ this.onKeyDown.bind(this) }
          autoComplete={ autoComplete }
          minRows={ 10 }
        />
      );
    } else if (this.props.geosuggest) {
      return (
        <Geosuggest
          name={ this.props.name }
          id={ inputId }
          className={ geosuggestClasses }
          inputClassName="InputField-Input"
          initialValue={ this.state.inputValue }
          onFocus={ this.handleGeoFocus.bind(this) }
          // onChange={this.onChange.bind(this)}
          onBlur={ this.handleGeoBlur.bind(this) }
          onSuggestSelect={ this.handleSuggestSelect.bind(this) }
          types={ this.props.geosuggestTypes }
          country={ country ? 'us' : null }
          placeholder={ null }
          // onKeyDown={this.onKeyDown.bind(this)}
        />
      );
    }

    return (
      <input
        { ...domOnlyProps({ disabled, name, maxLength, type }) }
        name={ this.props.name }
        id={ inputId }
        type={ inputType }
        className={ classes }
        disabled={ disabled }
        maxLength={ maxLength }
        value={ this.state.inputValue }
        onChange={ this.onChange.bind(this) }
        onBlur={ this.onBlur.bind(this) }
        onKeyDown={ this.onKeyDown.bind(this) }
        autoComplete={ autoComplete }
        ref={ this.props.name }
      />
    );
  }

  renderPlaceholder() {
    const { placeholder } = this.props;
    return (
      <span role="definition" className="InputField-Placeholder">{ placeholder }</span>
    );
  }

  renderRemoveIcon() {
    const { iconRemoveClick, iconRemove, remove } = this.props;
    if (remove) {
      const removeIconClasses = cx('InputField-IconButton', 'InputField-IconButton--remove');
      if (iconRemoveClick) {
        return (
          <button type="button" className={ removeIconClasses } onClick={ iconRemoveClick }>
            { iconRemove }
          </button>
        );
      }

      return (
        <i className={ removeIconClasses }>
          { iconRemove }
        </i>
      );
    }

    return false;
  }

  renderIcon(iconPosition) {
    const { icon, iconLeft, iconClick, iconLeftClick } = this.props;

    const leftClassname = {
      'InputField-IconButton': true,
      'InputField-IconButton--clickable': iconLeftClick,
      'InputField-IconButton--left': true,
    };

    const rightClassname = {
      'InputField-IconButton lk-form-Next-gtm': true,
      'InputField-IconButton--clickable': iconClick,
    };

    if ((iconPosition === 'right') && (icon)) {
      if (iconClick) {
        return (
          <button className={ cx(rightClassname) } type="button" onClick={ iconClick }>
            { icon }
          </button>
        );
      }

      return (
        <i className={ cx(rightClassname) }>
          { icon }
        </i>
      );
    } else if ((iconPosition === 'left') && (iconLeft)) {
      if (iconLeftClick) {
        return (
          <button className={ cx(leftClassname) } type="button" onClick={ iconLeftClick }>
            { icon }
          </button>
        );
      }

      return (
        <i className={ cx(leftClassname) } onClick={ iconLeftClick }>
          { iconLeft }
        </i>
      );
    }

    return false;
  }

  renderErrors() {
    const {
      error,
      formSubmitStatus,
      touched,
    } = this.props;

    if (error) {
      if (touched || formSubmitStatus === 'wrongPassword') {
        return <p className="InputField-Error">{ error }</p>;
      }
    }

    return null;
  }

  render() {
    const {
      formSubmitStatus,
      name,
      className,
      touched,
      error,
      icon,
      iconLeft,
      label,
      multiline,
      remove,
      resizable,
      required,
      success,
      disabled,
    } = this.props;
    const inputUniqueId = name || `genericInputFieldId_${ Date.now() }`;

    const inputFieldClasses = {
      InputField: true,
      'InputField--icon': icon,
      'InputField--iconLeft': iconLeft,
      'InputField--error': touched && error || formSubmitStatus === 'wrongPassword',
      'InputField--remove': remove,
      'InputField--multiline': multiline,
      'InputField--multiline-resizeable': resizable,
      'InputField--required': required,
      'InputField--success': success,
      disabled,
    };

    return (
      <div className={ cx(inputFieldClasses, className) }>
        <label className="InputField-Label" htmlFor={ inputUniqueId }>{ label }</label>
        <div className="InputField-InputHolder">
          { this.renderInput(inputUniqueId) }
          { this.renderPlaceholder() }
          { this.renderIcon('right') }
          { this.renderIcon('left') }
          { this.renderRemoveIcon() }
        </div>
        { this.renderErrors() }
      </div>
    );
  }
}

export default InputField;
