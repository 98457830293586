/**
 * Created by mateimisarca on 24/07/2018
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import loadingScreen from './loading-screen';
import inscriere from './inscriere';
import auth from './auth';
import courses from './cursuri';
import results from './results';
import admin from './admin';
import errors from './errors';

export default (history) => combineReducers({
  router: connectRouter(history),
  loadingScreen,
  inscriere,
  auth,
  courses,
  results,
  admin,
  errors,
  form,
});
