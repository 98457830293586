/**
 * Created by mateimisarca on 25.03.2021
 */

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { capitalize, find, map } from 'lodash';
import { change as changeFieldValue, Field, reduxForm } from 'redux-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { adminActionsFacade as adminActions } from '../../actions/admin';
import InputField from '../../components/inputfield';
import Dropdown from '../../components/dropdown';
import DropdownMenu from '../../components/dropdown-menu';
import getValidator from '../../lib/utils/validation';
import validation from './validation';

const submit = (push, values, dispatch) => {
  dispatch(adminActions.createSeries(values)).then(() => push(`/admin/series`));
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      adminActions,
    ), dispatch),
  };
}

const mapStateToProps = state => ({
  courses: state.admin.courses,
});

@compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'seriesForm',
    asyncValidate: getValidator(validation),
  }),
)
export default class SeriesAddPage extends Component {
  constructor(props) {
    super(props);

    const { getCourses } = props.actions;

    this.state = {
      fields: [
        {
          field: 'input',
          placeholder: 'Titlul Seriei',
          type: 'text',
          name: 'title',
          singleLine: true,
        },
        {
          field: 'select',
          placeholder: 'Cursul',
          type: 'text',
          name: 'course',
          variable: 'courses',
          singleLine: true,
        },
        {
          field: 'editor',
          placeholder: 'Notificarea eMail',
          type: 'text',
          name: 'emailText',
          singleLine: false,
        },
      ],
    };

    getCourses({ hasSeries: true });

    this.renderInput = this.renderInput.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderTextEditor = this.renderTextEditor.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  onItemClick(onChange, name, id, value) {
    onChange(id);
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
        disabled={ disabled }
      />
    );
  }

  renderTextEditor({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <div className="Editor">
        <CKEditor
          name={ input.name }
          placeholder={ placeholder }
          dynamicPlaceholder
          debounceTimeout={ 300 }
          error={ meta.error || error }
          touched={ meta.touched }
          className={ className }
          type={ type }
          multiline={ multiline }
          disabled={ disabled }
          editor={ ClassicEditor }
          // data="<p>Hello from CKEditor 5!</p>"
          onChange={ (event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            input.onChange(data);
          } }
          onBlur={ (event, editor) => {
            // console.log('Blur.', editor);
          } }
          onFocus={ (event, editor) => {
            // console.log('Focus.', editor);
          } }
        />
      </div>
    );
  }

  renderDropdown(items, { input, placeholder, meta: { touched, error } }) {
    return (
      <div>
        <Dropdown
          noPadding
          label={ find(items, item => item.id === input.value)?.name ||
          <span style={ { color: '#898989', textTransform: 'none' } }>{ capitalize(placeholder) }</span> }
          size="large"
          onClick={ this.onDropdownClick }
          onClickOutside={ this.onDropdownClick }
          blockButton
          position="right"
          theme="dark"
        >
          <DropdownMenu
            items={ items || [] }
            onItemClick={ this.onItemClick.bind(this, input.onBlur, input.name) }
          />
        </Dropdown>
        { touched && error && <span className="inscriere-error">{ error }</span> }
      </div>
    );
  }

  render() {
    const { fields } = this.state;
    const {
      handleSubmit,
      history: {
        push,
      },
      match: {
        url,
      },
    } = this.props;
    const path = url;

    return (
      <div className="inscriere-page">

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <h2>{ 'Serii' }</h2>
                <p>{ `Introduceți datele seriei` }</p>
                <form onSubmit={ handleSubmit(submit.bind(this, push)) }>
                  <div className="row">

                    { fields.map((field, index) => {
                      const list = field.variable ? this.props[field.variable] : null;
                      const className = field.field === 'editor' || field.singleLine || field.field === 'select-list' || field.field === 'checkbox' ?
                        'col-md-12 col-sm-12 col-xs-12 pl-0' : 'col-md-6 col-sm-6 col-xs-12';
                      console.log('@@@-2 ', list);
                      return (
                        <div className={ className } key={ index }>
                          <div className="single-input-field">
                            { field.field === 'input' ? (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderInput }
                                placeholder={ field.placeholder }
                                className="form-element"
                                disabled={ field.disabled }
                                multiline={ !field.singleLine }
                              />
                            ) : field.field === 'editor' ? (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderTextEditor }
                                placeholder={ field.placeholder }
                                className="form-element"
                                disabled={ field.disabled }
                              />
                            ) : (
                              <Field
                                name={ field.name }
                                type={ field.type }
                                component={ this.renderDropdown.bind(this, map(list, item => ({
                                  id: item._id,
                                  name: item.title,
                                }))) }
                                placeholder={ field.placeholder }
                                className="form-element"
                              />
                            ) }
                          </div>

                        </div>
                      );
                    }) }

                  </div>
                  <div className="">
                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Salvează" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
