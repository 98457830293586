/**
 * Created by mateimisarca on 2019-06-15
 */

import React, { Component } from 'react';

import Heading from '../../components/heading';

import banner from '../../assets/images/banner_1920x350-1.jpg';

import CCD_Logo from './../../assets/images/logo-CCDB.png';
import CPR_Logo from './../../assets/images/logo-cpr.jpg';
import PL_Logo from './../../assets/images/logo-printrelitere.png';
import AL_Logo from './../../assets/images/logo-aletheea.jpg';
import LiteraEducational_Logo from './../../assets/images/LiteraEducational.png';

import './partners-page.scss';

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/parteneri',
    text: 'Parteneri',
  },
];

const partners = [
  {
    link: 'https://www.literaeducational.ro/',
    logo: LiteraEducational_Logo,
  },
  {
    link: 'http://www.ccd-bucuresti.org',
    logo: CCD_Logo,
  },
  {
    link: 'http://www.literaeducational.ro/clubul-profesorilor/',
    logo: CPR_Logo,
  },
  {
    link: 'https://www.facebook.com/Printre-Litere-147918012675829/',
    logo: PL_Logo,
  },
  {
    link: 'https://www.aletheea.ro/',
    logo: AL_Logo,
  },
];

export default class PartnersPage extends Component {
  render() {
    return (
      <div className="partners">
        <Heading title="Parteneri" links={headingLinks} image={banner} />

        <div className="traineri instructor-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              {partners.map((partner, index) => (

                <div className="col-md-3 col-sm-6 col-xs-12" key={index}>
                  <div className="instructor-member">
                    <div className="instructor-member-thumb">
                      <a className="partners-link" href={partner.link} target="_blank">
                        <img src={partner.logo} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    );
  }
}
