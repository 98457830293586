/**
 * Created by matei on 16/11/2019
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addToken } from '../../lib/utils/request';

import { authActionsFacade as authActions } from './../../actions/auth';

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      authActions,
    ), dispatch),
  };
}

@connect(null, mapDispatchToProps)
export default class AppContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    const {
      actions: {
        getUserProfile,
      },
    } = props;

    const jwtToken = localStorage.getItem('jwtToken');

    this.state = {
      isLoaded: false,
    };

    if (jwtToken) {
      addToken(jwtToken);

      getUserProfile().then(() => this.setState({
        isLoaded: true,
      })).catch(() => this.setState({
        isLoaded: true,
      }));
    } else {
      this.state = {
        isLoaded: true,
      };
    }
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      isLoaded,
    } = this.state;

    return (
      <div className="appContainer">
        {isLoaded && children}
      </div>
    );
  }
}
