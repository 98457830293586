/**
 * Created by mateimisarca on 2019-06-14
 */

import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

export default class P404 extends Component {
  render() {
    return (
      <div className="error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>404, Not Found</h1>
                <h2>Oops! Pagina pe care o cautati nu a fost gasita.</h2>
                <p>
                  Ne pare rau, dar pagina cautata nu exista sau este temporar indisponibila.
                </p>
                <Link to="/">Inapoi la site</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
