/**
 * Created by mateimisarca on 24/07/2018
 */

import { pageview } from 'react-ga';

import { LOCATION_CHANGE } from './../../constants';

export default function reportPageView() {
  return next => (action) => {
    if (action.type === LOCATION_CHANGE) {
      console.info(`Route Changed: ${action.payload.location.pathname}`);
      pageview(action.payload.location.pathname);
    }
    return next(action);
  };
}
