/**
 * Created by matei on 20/11/2019
 */

import React, { Component } from 'react';
import { func, object } from 'prop-types';
// import PDFViewer from 'pdf-viewer-reactjs';
import { isEqual, uniqueId } from 'lodash';

import config from './../../config';

// import 'react-pdf/dist/Page/AnnotationLayer.css';
import './materiale.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.js`;

export default class CursMaterials extends Component {
  static propTypes = {
    updateStep: func,
    result: object,
    element: object,
  };

  constructor(props) {
    super(props);
    const {
      updateStep,
      result,
      element,
    } = props;

    if (updateStep) {
      updateStep({
        id: element._id,
      }, result._id).then(() => this.markAsDone());
    }

    this.state = {
      numPages: null,
      pageNumber: 1,
    };

    this.markAsDone = this.markAsDone.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.element, this.props.element);
  }

  markAsDone() {
    const {
      updateStep,
      result,
      element,
    } = this.props;

    if (updateStep) {
      updateStep({
        id: element._id,
        done: true,
      }, result._id);
    }
  }

  render() {
    const {
      element,
    } = this.props;

    const title = element.title;

    return (
      <div className="materiale">
        {
          element?.materials?.map((element, index) => {
            let requirements = null;
            if (element.requirements) {
              requirements = <div dangerouslySetInnerHTML={ { __html: element.requirements } } />;
            }

            if (element.materialType === 'pdf') {
              return (
                <div className="materiale-pdf" key={ uniqueId('pdf_') }>
                  { element.materialFileName && (
                    <iframe
                      src={ `${ config.imagesURL }/${ element.materialFileName }` }
                      style={ { width: '100%', height: '100vh' } }
                      frameBorder="0"
                    />
                  ) }

                </div>
              );
            } else if (element.materialType === 'mp4') {
              return (
                <div className="materiale-mp4" key={ uniqueId('pdf_') }>
                  { element.materialFileName && (
                    <video controls style={ { width: '100%', height: '100vh' } }>
                      <source src={ `${ config.imagesURL }/${ element.materialFileName }` } type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) }

                </div>
              );
            } else if (requirements) {
              return (
                <div className="materiale-text" key={ uniqueId('req_') }>
                  <h3>{ title }</h3>
                  { requirements }
                </div>
              );
            }
          })
        }
      </div>
    );
  }
}
