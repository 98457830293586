/**
 * Created by mateimisarca on 20.03.2021
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import cx from 'classnames';
import { Row, Col, Container } from 'react-bootstrap';

import { ROLES } from '../../constants/roles';
import SidebarNav from '../../components/sidebar-nav';
import { menu } from '../../components/sidebar-nav/menu';

import SubscriptionsAdminPage from '../../pages/subscriptions-admin-page';
import ResultsAdminPage from '../../pages/results-admin-page';
import SeriesAdminPage from '../../pages/series-admin-page';
import P404 from '../../pages/404-page';

import './admin-container.scss';
import SeriesAddPage from '../../pages/series-add-page';

const mapStateToProps = (state) => ({
  profile: state.auth.user,
});

@connect(mapStateToProps)
export default class AdminContainer extends Component {
  render() {
    const { profile } = this.props;
    return (
      <Container fluid>
        <Row className="adminContainer">
          <Col id="sidebarWrapper" className={ cx('SidebarWrapper', {
            // 'SidebarWrapper--mobile': isMobileOnly,
            // 'SidebarWrapper--mobileExpanded': isNavigationOpen,
          }) }>
            <SidebarNav />
          </Col>
          <Col id="pageContentWrapper">
            <Switch>
              { profile?.role !== ROLES.ADMIN && (
                <Redirect to="/P403" />
              ) }

              <Redirect from="/admin" exact push to={ menu[0].path } />
              <Route path="/admin/subscriptions" exact component={ SubscriptionsAdminPage } />
              <Route path="/admin/results" exact component={ ResultsAdminPage } />
              <Route path="/admin/series" exact component={ SeriesAdminPage } />
              <Route path="/admin/series/new" exact component={ SeriesAddPage } />

              <Route component={ P404 } />
            </Switch>
          </Col>
        </Row>
      </Container>
    );
  }
}
