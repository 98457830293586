/**
 * Created by matei on 13/11/2019
 */

import * as request from './../lib/utils/request';
import config from './../config';
import { AUTH } from './../constants';

function login(values) {
  return {
    types: [AUTH.login, AUTH.loginSuccess, AUTH.loginFail],
    promise: request.post(`${ config.apiURL }/login`, values),
  };
}

function logout() {
  return {
    type: AUTH.logout,
  };
}

function verifyToken(token) {
  return {
    types: [AUTH.verifyToken, AUTH.verifyTokenSuccess, AUTH.verifyTokenFail],
    promise: request.post(`${ config.apiURL }/verify-account`, {
      verifyToken: token,
    }),
  };
}

function register(values) {
  return {
    types: [AUTH.register, AUTH.registerSuccess, AUTH.registerFail],
    promise: request.post(`${ config.apiURL }/register`, values),
  };
}

function resetUserPassword(values) {
  return {
    types: [AUTH.resetUserPassword, AUTH.resetUserPasswordSuccess, AUTH.resetUserPasswordFail],
    promise: request.post(`${ config.apiURL }/reset-password`, values),
  };
}

function resetUserPasswordMail(values) {
  return {
    types: [AUTH.resetUserPasswordMail, AUTH.resetUserPasswordMailSuccess, AUTH.resetUserPasswordMailFail],
    promise: request.post(`${ config.apiURL }/password-reset`, values),
  };
}

function getUserProfile() {
  return {
    types: [AUTH.getUserProfile, AUTH.getUserProfileSuccess, AUTH.getUserProfileFail],
    promise: request.get(`${ config.apiURL }/profile`),
  };
}

function updateProfile(values) {
  return {
    types: [AUTH.setUserProfile, AUTH.setUserProfileSuccess, AUTH.setUserProfileFail],
    promise: request.post(`${ config.apiURL }/profile`, values),
  };
}

export const authActionsFacade = {
  login,
  logout,
  register,
  verifyToken,
  updateProfile,
  getUserProfile,
  resetUserPassword,
  resetUserPasswordMail,
};
