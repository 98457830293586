/**
 * Created by mateimisarca on 24/07/2018
 */

import * as request  from './../lib/utils/request';
import config        from './../config';
import { INSCRIERE } from './../constants';

function getJudete() {
  return {
    types: [INSCRIERE.judete, INSCRIERE.judeteSuccess, INSCRIERE.judeteFail],
    promise: request.get(`${config.apiURL}/schools/judete`),
  };
}

function getLocalitateSuperioara(judet) {
  return {
    types: [INSCRIERE.getlocalitateSuperioara, INSCRIERE.getlocalitateSuperioaraSuccess, INSCRIERE.getlocalitateSuperioaraFail],
    promise: request.get(`${config.apiURL}/schools/localitateSuperioara?judet=${judet}`),
  };
}

function getLocalitate(localitateSuperioara) {
  return {
    types: [INSCRIERE.getlocalitate, INSCRIERE.getlocalitateSuccess, INSCRIERE.getlocalitateFail],
    promise: request.get(`${config.apiURL}/schools/localitate?localitateSuperioara=${localitateSuperioara}`),
  };
}

function getUnitatiInvatamant(localitate, judet) {
  return {
    types: [INSCRIERE.getUnitatiInvatamant, INSCRIERE.getUnitatiInvatamantSuccess, INSCRIERE.getUnitatiInvatamantFail],
    promise: request.get(`${config.apiURL}/schools/unitatiInvatamant?localitate=${localitate}&judet=${judet}`),
  };
}

function getLocalitateSuperioara2(judet) {
  return {
    types: [INSCRIERE.getlocalitateSuperioara2, INSCRIERE.getlocalitateSuperioaraSuccess2, INSCRIERE.getlocalitateSuperioaraFail2],
    promise: request.get(`${config.apiURL}/schools/localitateSuperioara?judet=${judet}`),
  };
}

function getLocalitate2(localitateSuperioara) {
  return {
    types: [INSCRIERE.getlocalitate2, INSCRIERE.getlocalitateSuccess2, INSCRIERE.getlocalitateFail2],
    promise: request.get(`${config.apiURL}/schools/localitate?localitateSuperioara=${localitateSuperioara}`),
  };
}

function getUnitatiInvatamant2(localitate, judet) {
  return {
    types: [INSCRIERE.getUnitatiInvatamant2, INSCRIERE.getUnitatiInvatamantSuccess2, INSCRIERE.getUnitatiInvatamantFail2],
    promise: request.get(`${config.apiURL}/schools/unitatiInvatamant?localitate=${localitate}&judet=${judet}`),
  };
}

function addInscriere(values) {
  return {
    types: [INSCRIERE.addInscriere, INSCRIERE.addInscriereSuccess, INSCRIERE.addInscriereFail],
    promise: request.post(`${config.apiURL}/subscriptions/create`, values),
  };
}

export const inscriereActionsFacade = {
  getJudete,
  getLocalitateSuperioara,
  getLocalitate,
  getUnitatiInvatamant,
  getLocalitateSuperioara2,
  getLocalitate2,
  getUnitatiInvatamant2,
  addInscriere,
};

