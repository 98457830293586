/**
 * Created by matei on 2019-06-19
 */

import React, { Component } from 'react'

import './checkbox.scss';

class Checkbox extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const {onChange} = this.props;

    if (onChange) {
      onChange(this.refs.check_me.checked);
    }
  }

  render() {
    const {
      checked,
      label,
      // className,
    } = this.props;

    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            // value={label}
            checked={checked}
            onChange={this.onChange}
            ref="check_me"
          />

          <span>{label}</span>
        </label>
      </div>
    )
  }
}

export default Checkbox
