/**
 * Created by matei on 20/11/2019
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './course-step.scss';

export default class CourseStep extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    photo: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    onClick: () => {
    },
  };

  render() {
    const {
      title,
      content,
      photo,
      // icon,
      disabled,
      onClick,
      type,
    } = this.props;

    const classNames = cx('why-choose-inner courseStep', {
      'courseStep--disabled': disabled,
    });

    let icon = 'archway';
    if (type === 'materiale-curs') {
      icon = 'book';
    } else if (type === 'grila') {
      icon = 'check-square';
    } else if (type === 'aplicatie-libera') {
      icon = 'pen-fancy';
    }
    if (disabled) {
      icon = 'lock';
    }
    const iconClassNames = cx('fas', `fa-${ icon }`);

    return (
      <div className={ classNames } onClick={ () => !disabled && onClick() }>
        <div className="why-choose-thumb">
          <img src={ photo } alt="" />
          <div className="why-choose-icon">
            <i className={ iconClassNames } />
          </div>
        </div>
        <div className="why-choose-text">
          <h2>
            <a href="#" onClick={ (evt) => evt.preventDefault() }>
              { title }
            </a>
          </h2>
          <p>
            { content }
          </p>
        </div>
      </div>
    );
  }
}
