/**
 * Created by matei on 13/11/2019
 */

import { required, match } from './../../lib/utils/validations';

const resetPasswordValidation = (data) => {
  return {
    password: [required],
    passwordConfirmation: [required, match('password')],
  };
};

export default resetPasswordValidation;

