/**
 * Created by mateimisarca on 25.03.2021
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { capitalize, map, find, debounce } from 'lodash';
import { Card } from 'react-bootstrap';

import { adminActionsFacade as adminActions } from '../../actions/admin';
import DropdownMenu from '../../components/dropdown-menu';
import Dropdown from '../../components/dropdown';
import DataTable from '../../components/data-table';
import InputField from '../../components/inputfield';

import './series-admin-page.scss';

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      adminActions,
    ), dispatch),
  };
}

const mapStateToProps = state => ({
  courses: state.admin.courses,
  series: state.admin.series,
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SeriesAdminPage extends Component {
  constructor(props) {
    super(props);

    const { getSeries, getCourses } = props.actions;

    getCourses();
    getSeries({ course: null });

    this.state = {
      pagination: {},
      course: null,
      find: '',
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.getSeries = this.getSeries.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  onDropdownClick() {
  }

  onItemClick(id, value) {
    const { getSeries } = this.props.actions;
    const { pagination, find } = this.state;

    this.setState({
      pagination: {},
      course: id,
    });

    getSeries({ course: id, ...pagination, find });
  }

  handleFindChange = debounce(
    value => {
      const { pagination, course } = this.state;
      const { getSeries } = this.props.actions;
      this.setState({ find: value });
      getSeries({ course, ...pagination, find: value });
    },
    1000,
    { leading: false },
  );

  getSeries(pagination) {
    const { getSeries } = this.props.actions;
    const { course, find } = this.state;

    const newPagination = Object.assign({}, this.state.pagination, pagination);
    this.setState({
      pagination: newPagination,
    });
    getSeries({
      course,
      ...newPagination,
      find,
    });
  }

  renderDropdown(courses, placeholder, selectedCourse) {
    return (
      <Dropdown
        className="seriesAdmin-dropdown"
        noPadding
        label={ selectedCourse || <span style={ { color: '#898989', textTransform: 'none' } }>
            { capitalize(placeholder) }
          </span>
        }
        size="large"
        onClick={ this.onDropdownClick }
        onClickOutside={ this.onDropdownClick }
        blockButton
        position="right"
        theme="dark"
      >
        <DropdownMenu
          items={ map(courses, course => ({
            id: course._id,
            name: course.title,
          })) }
          onItemClick={ this.onItemClick/*.bind(this, input.onBlur, input.name)*/ }
        />
      </Dropdown>
    );
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        multiline={ multiline }
        disabled={ disabled }
      />
    );
  }

  renderDataTable() {
    const {
      series,
      location: {
        pathname
      },
      history: {
        push,
      },
    } = this.props;

    return (
      <Card>
        <DataTable
          mainButton={ 'Adaugă o nouă serie' }
          mainButtonOnClick={ () => push(`${ pathname }/new`) }
          columns={ {
            'title': {
              units: 'text',
            },
            'courseTitle': {
              units: 'text',
            },
            'created': {
              units: 'date',
            },
            'updated': {
              units: 'date',
            },
          } }
          rows={ series.rows }
          count={ series.count }
          currentPage={ series.currentPage }
          pages={ series.pages }
          pagination
          onTableUpdate={ this.getSeries }
          itemsPerPage={ series.itemsPerPage }
          perPage={ series.perPage }
          totalItems={ series.count }
          // onRowClick={ (id, row) => goTo(`${ page }/${ id }`) }
        />
      </Card>
    );
  }

  render() {
    const { courses } = this.props;
    const { course } = this.state;

    return (
      <div className="seriesAdmin">
        <div className="seriesAdmin-filtersWrapper">
          { this.renderDropdown(courses, 'COURSE', find(courses, o => o._id === course)?.title) }
          { this.renderInput({
            placeholder: 'filter',
            input: {
              name: 'filter',
              onChange: this.handleFindChange,
            },
            meta: {},
            className: 'seriesAdmin-input',
          }) }
        </div>
        { this.renderDataTable() }
      </div>
    );
  }
}
