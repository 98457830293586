/**
 * Created by mateimisarca on 24/09/2020
 */

import React, { Component, forwardRef, Fragment } from 'react';
import { Button, Table, Dropdown, Spinner } from 'react-bootstrap';
import { map } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';

// import { makeSelectCurrentLocation } from 'containers/App/selectors';

import Pagination from './../pagination';

import ValueFormattingService from './../../lib/utils/valueFormatter';

import './data-table.scss';

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ ref }
    onClick={ evt => {
      evt.stopPropagation();
      evt.preventDefault();
      onClick(evt);
    } }
  >
    { children }
    <i className="fas fa-ellipsis-v" />
  </a>
));

const mapStateToProps = state => ({
  // page: makeSelectCurrentLocation(state),
});

export default @compose(
  connect(mapStateToProps),
)
class DataTable extends Component {
  static defaultProps = {
    pagination: true,
  };

  constructor() {
    super();

    this.onPageChange = this.onPageChange.bind(this);
    this.state = {
      page: 1,
      perPage: 10,
    };
  }

  onPageChange({ ...rest }) {
    const { currentPage, onTableUpdate, perPage, page } = this.props;

    if (page !== currentPage) {
      onTableUpdate && onTableUpdate({
        page: currentPage,
        perPage,
        ...rest,
      });
    }
  }

  renderTopArea() {
    const {
      mainButton,
      mainButtonOnClick,
    } = this.props;

    return (
      <div className="DataTable-topArea">
        <div className="DataTable-buttonArea">
          { mainButton && (<Button type="main" onClick={ evt => mainButtonOnClick && mainButtonOnClick(evt) }>
            { mainButton }
          </Button>) }
        </div>
      </div>
    );
  }

  renderHeader() {
    const {
      columns,
      page,
      actions,
    } = this.props;

    return (
      <thead className="thead-dark">
      <tr>
        { map(Object.keys(columns), column => (
          <th key={ `${ column }_h` }>
            { column }
          </th>
        )) }
        { actions && (
          <th>
            Acțiuni
          </th>
        ) }
      </tr>
      </thead>
    );
  }

  renderActionsButton(row, index) {
    const { onActionClick, actions, intl, page } = this.props;

    return (
      <td className={ cx('DataTable-cell', 'DataTable-cell--icon') }
          onClick={ evt => evt.stopPropagation() }
      >
        <Dropdown>
          <Dropdown.Toggle as={ CustomToggle } />
          <Dropdown.Menu>
            { map(actions, action => (
              <Dropdown.Item
                key={ action.id }
                onClick={ onActionClick && onActionClick.bind(this, action, row, index) }
              >
                { action.id }
              </Dropdown.Item>
            )) }
          </Dropdown.Menu>
        </Dropdown>
      </td>
    );
  }

  renderEmptyBody() {
    const { isDataLoading } = this.props;

    if (isDataLoading) {
      return (
        <div className="DataTable-emptyBody">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div className="DataTable-emptyBody">
        <p>Nu a fost găsit niciun rezultat pentru selecția curentă!</p>
      </div>
    );
  }

  renderBody() {
    const { columns, rows, onRowClick, actions, onActionClick, expanded, expandedElement } = this.props;

    return (
      <tbody>
      { map(rows, (row, index) => (
        <Fragment key={ row?._id }>
          <tr
            className={ cx('DataTable-row', {
              'DataTable-row--clickable': onRowClick,
            }) }
            onClick={ () => onRowClick && onRowClick(index, row?.id, row) }
          >
            { map(Object.keys(columns), columnId => (
              <td key={ `${ columnId }_${ index }` }>
                { columns[columnId].units === 'custom' ? columns[columnId].customCell(row, columnId) : row[columnId] && ValueFormattingService.prettyFormatValue(row[columnId], columns[columnId].units) }
              </td>
            )) }
            { actions && this.renderActionsButton(row, index) }
          </tr>
          { expanded === index && (
            <tr>
              <td colSpan={ Object.keys(columns).length }>
                { expandedElement }
              </td>
            </tr>
          ) }
        </Fragment>
      )) }

      </tbody>
    );
  }

  render() {
    const {
      pagination,
      count,
      currentPage,
      pages,
      itemsPerPage,
      perPage,
      rows,
      totalItems,
      isDataLoading,
    } = this.props;

    if (!rows || !rows.length || isDataLoading) {
      return (
        <div className="DataTable">
          { this.renderTopArea() }
          { this.renderEmptyBody() }
        </div>
      );
    }

    return (
      <div className="DataTable">
        { this.renderTopArea() }
        <Table
          striped
          // bordered
          hover
          responsive
          size="sm"
        >
          { this.renderHeader() }
          { this.renderBody() }
        </Table>
        { pagination && (
          <Pagination
            count={ count }
            currentPage={ currentPage }
            totalPages={ pages }
            onClick={ this.onPageChange }
            itemsPerPage={ itemsPerPage }
            perPage={ perPage }
            totalItems={ totalItems }
          />
        ) }
      </div>
    );
  }
}
