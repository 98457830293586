/**
 * Created by mateimisarca on 23/11/2019
 */

import React, { PureComponent } from 'react';
import { findIndex, find } from 'lodash';

import Choice from '../../components/choice';

export default class CursGrila extends PureComponent {
  constructor(props) {
    super(props);
    const {
      updateStep,
      result,
      element,
    } = props;

    const resultElementIndex = findIndex(result.elements, o => o.courseElementId === element._id);

    this.state = {
      resultElementIndex,
    };

    if (updateStep) {
      updateStep({
        id: element._id,
      }, result._id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      result,
      element,
    } = nextProps;

    const resultElementIndex = findIndex(result.elements, o => o.courseElementId === element._id);

    this.setState({
      resultElementIndex,
    });
  }

  onSolved(itemSolved, answers) {
    const {
      updateStep,
      result,
      element,
    } = this.props;
    const { resultElementIndex } = this.state;

    if (updateStep) {
      updateStep({
        id: element._id,
        itemSolved: {
          ...answers,
          id: itemSolved._id,
        },
        done: result.elements[resultElementIndex]?.choiceItems.length + 1 === element.choiceItems.length ? 2 : 1,
      }, result._id);


    }
  }

  render() {
    const { element, result } = this.props;
    const { resultElementIndex } = this.state;

    if (
      !element ||
      !element.choiceItems ||
      element.choiceItems.length === 0 ||
      !result ||
      result.elements.length <= 0
    ) {
      return null;
    }

    return (
      <div>
        { element.choiceItems.map(item => (
          <Choice
            { ...item }
            key={ item._id }
            itemAnswers={ find(result.elements[resultElementIndex]?.choiceItems, o => o.id === item._id) }
            onSolved={ this.onSolved.bind(this, item) }
          />
        )) }
      </div>
    );
  }
}
