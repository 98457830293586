/**
 * Created by mateimisarca on 11/03/2018
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import Icon from './../../components/icon';

import './dropdown-menu.scss';

export default class DropdownMenu extends Component {
  render() {
    const {items} = this.props;
    const dropdownItems = items.map((item, i) => {
      if (item.type === 'header') {
        return (
          <li
            className={classNames('DropdownMenu-header', {'DropdownMenu-separator': item.separator})}
            key={`item-${i}`}
          >
            {item.name}
          </li>
        );
      }

      return (
        <li
          key={`item-${i}`}
          className={classNames('DropdownMenu-item', {
            'DropdownMenu-separator': item.separator,
            'DropdownMenu-disabled': item.disabled,
          })}
          onClick={item.disabled !== true ? this.props.onItemClick.bind(this, item.id, item.name) : null}
        >
          {/*{item.icon && <Icon className="DropdownMenu-icon" name={item.icon} />}*/}
          <span>
            {item.name}
          </span>
        </li>
      );
    });

    return (
      <ul className="DropdownMenu" role="menu">
        {dropdownItems}
      </ul>
    );
  }
}

DropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
};

DropdownMenu.defaultProps = {
  items: [],
  onItemClick: () => {
  },
};
