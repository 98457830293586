/**
 * Created by mateimisarca on 20.03.2021
 */

import qs from 'qs';

import * as request from '../lib/utils/request';
import { ADMIN, INSCRIERE } from '../constants';
import config from '../config';


function getSubscriptions(params = {}) {
  return {
    types: [ADMIN.getSubscriptions, ADMIN.getSubscriptionsSuccess, ADMIN.getSubscriptionsFail],
    promise: request.get(`${ config.apiURL }/admin/subscriptions${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

function getResults(params = {}) {
  return {
    types: [ADMIN.getResults, ADMIN.getResultsSuccess, ADMIN.getResultsFail],
    promise: request.get(`${ config.apiURL }/admin/results${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

function getSeries(params = {}) {
  return {
    types: [ADMIN.getSeries, ADMIN.getSeriesSuccess, ADMIN.getSeriesFail],
    promise: request.get(`${ config.apiURL }/admin/series${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

function getCourses(params = {}) {
  return {
    types: [ADMIN.getCourses, ADMIN.getCoursesSuccess, ADMIN.getCoursesFail],
    promise: request.get(`${ config.apiURL }/admin/courses${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

function createSeries(values) {
  return {
    types: [ADMIN.createSeries, ADMIN.createSeriesSuccess, ADMIN.createSeriesFail],
    promise: request.post(`${ config.apiURL }/admin/series`, values),
  };
}

function assignSeries(values) {
  return {
    types: [ADMIN.assignSeries, ADMIN.assignSeriesSuccess, ADMIN.assignSeriesFail],
    promise: request.post(`${ config.apiURL }/admin/series/assign`, values),
  };
}

function updateModuleActive(values) {
  return {
    types: [ADMIN.moduleActive, ADMIN.moduleActiveSuccess, ADMIN.moduleActiveFail],
    promise: request.post(`${ config.apiURL }/admin/series/module-active`, values),
  };
}

function exportSubscriptions(params = {}) {
  return {
    types: [ADMIN.exportExcel, ADMIN.exportExcelSuccess, ADMIN.exportExcelFail],
    promise: request.get(`${ config.apiURL }/admin/subscriptions/excel${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

function exportResults(params = {}) {
  return {
    types: [ADMIN.exportResultsExcel, ADMIN.exportResultsExcelSuccess, ADMIN.exportResultsExcelFail],
    promise: request.get(`${ config.apiURL }/admin/results/excel${ qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    }) }`),
  };
}

export const adminActionsFacade = {
  getSubscriptions,
  getResults,
  getCourses,
  getSeries,
  createSeries,
  assignSeries,
  updateModuleActive,
  exportSubscriptions,
  exportResults,
};




