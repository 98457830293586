import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { ROLES } from '../../constants/roles';

const menus = [
  {
    link: '/',
    title: 'Acasă',
  },
  {
    link: '/cursuri',
    title: 'Cursuri',
  },
  {
    link: '/traineri',
    title: 'Formatori',
  },
  {
    link: '/parteneri',
    title: 'Parteneri',
  },
  {
    link: '/contact',
    title: 'Contact',
  },
];

const adminMenus = [
  {
    link: '/admin',
    title: 'Admin',
  },
];

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  profile: state.auth.user,
});

@connect(mapStateToProps)
export default class Menu extends Component {
  constructor(props) {
    super(props);

    const topMenus = props?.profile?.role === ROLES.ADMIN ? [...menus, ...adminMenus] : menus;

    this.state = {
      menus: topMenus,
    };
  }

  componentDidMount() {
    const { profile } = this.props;

    const topMenus = profile?.role === ROLES.ADMIN ? [...menus, ...adminMenus] : menus;
    this.setState({
      menus: topMenus,
    });
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    const { profile: oldProfile } = prevProps;

    if (!isEqual(profile, oldProfile)) {
      const topMenus = profile?.role === ROLES.ADMIN ? [...menus, ...adminMenus] : menus;
      this.setState({
        menus: topMenus,
      });
    }
  }

  render() {
    return (
      <nav id="main-menu" className="main-menu">
        <ul>
          { this.state.menus.map((menu, index) => (
            <li key={ index }>
              <Link to={ menu.link }>{ menu.title }</Link>
            </li>
          )) }
        </ul>
      </nav>
    );
  }
}
