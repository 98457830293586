/**
 * Created by matei on 20/11/2019
 */

import photo from '../../assets/images/banner_420x230_4.jpg';
import icon from '../../assets/icon/locked.png';

export default [
  {
    photo: photo,
    icon: icon,
    type: 'materiale-curs'
  },
  {
    photo: photo,
    icon: icon,
    type: 'grila'
  },
  {
    photo: photo,
    icon: icon,
    type: 'aplicatie-libera'
  },
];
