/**
 * Created by mateimisarca on 23/11/2019
 */

import { RESULTS } from './../constants';

const initialState = {
  status: null,
  result: null,
  results: [],
};

export default function courses(state = initialState, action) {
  switch (action.type) {
    case RESULTS.getResults:
      return {
        ...state,
        status: 'loading',
      };

    case RESULTS.getResultsSuccess:
      return {
        ...state,
        status: 'success',
        result: action.payload.result,
      };

    case RESULTS.getResultsFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case RESULTS.updateStep:
      return {
        ...state,
        status: 'loading',
      };

    case RESULTS.updateStepSuccess:
      return {
        ...state,
        status: 'success',
        result: action.payload.result,
      };

    case RESULTS.updateStepFail:
      return {
        ...state,
        status: 'error',
        error: action.error?.error,
      };

    case RESULTS.getCourseResults:
      return {
        ...state,
        status: 'loading',
      };

    case RESULTS.getCourseResultsSuccess:
      return {
        ...state,
        results: action.payload.result,
        status: 'success',
      };

    case RESULTS.getCourseResultsFail:
      return {
        ...state,
        error: action.error?.error,
        status: 'error',
      };


    default:
      return state;
  }
}
