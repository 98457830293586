/**
 * Created by mateimisarca on 26/11/2019
 */

import React, { PureComponent } from 'react';
import { change, Field, reduxForm } from 'redux-form';

import InputField from '../../../components/inputfield';
import getValidator from '../../../lib/utils/validation';
import aplicatieValidation from './aplicatie.validation';
import { event } from 'react-ga';

import './module-aplicatie.scss';
import { find, findIndex } from 'lodash';
import { connect } from 'react-redux';

const submit = (updateStep, result, element, values, dispatch) => {
  updateStep({
    id: element._id,
    application: {
      question: element.application.question,
      answer: values.app,
    },
    done: true,
  }, result._id);
};

function mapStateToProps(state, ownProps) {
  const {
    result,
    element,
  } = ownProps;
  const app = find(result.elements, o => o.courseElementId === element._id)?.application?.answer;

  return {
    app,
    initialValues: {
      app,
    },
  };
}

@connect(mapStateToProps)
@reduxForm({
  form: 'appForm',
  asyncValidate: getValidator(aplicatieValidation),
})
export default class ModuleApplication extends PureComponent {
  constructor(props) {
    super(props);
    const {
      updateStep,
      result,
      element,
    } = props;

    const resultElementIndex = findIndex(result.elements, o => o.courseElementId === element._id);

    this.state = {
      resultElementIndex,
    };

    if (updateStep) {
      updateStep({
        id: element._id,
      }, result._id);
    }
    this.renderInput = this.renderInput.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      app,
      dispatch,
    } = this.props;

    if (!!app) {
      dispatch(change('appForm', 'app', app));
    }
  }

  renderInput({ input, placeholder, icon, className, meta, type, error, multiline, disabled }) {
    const {
      autoComplete,
    } = this.props;

    const domOnlyProps = {};
    if (autoComplete) {
      domOnlyProps.autoComplete = autoComplete;
    }

    return (
      <InputField
        { ...domOnlyProps }
        { ...input }
        name={ input.name }
        placeholder={ placeholder }
        dynamicPlaceholder
        debounceTimeout={ 300 }
        error={ meta.error || error }
        touched={ meta.touched }
        className={ className }
        type={ type }
        disabled={ disabled }
        multiline={ multiline }
      />
    );
  }

  render() {
    const {
      handleSubmit,
      updateStep,
      result,
      element,
      app,
      module,
      currentStep,
      push,
    } = this.props;

    return (
      <form className="aplicatie-wrapper" onSubmit={ handleSubmit(submit.bind(this, updateStep, result, element)) }>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-page-form">
              <h2>{ element.title }</h2>
              <p dangerouslySetInnerHTML={ { __html: element.application.question } } />

              <div className="single-input-field">
                <Field
                  name="app"
                  type="text"
                  component={ this.renderInput }
                  placeholder="Aplicația dumneavoastra"
                  className="form-element aplicatie-input"
                  multiline
                  disabled={ !!app }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={ { marginBottom: '20px' } }>
          <div className="col-md-6">
            <div className="single-input-fieldsbtn">
              <input type="submit" value="Trimite" disabled={ !!app } />
            </div>
          </div>
          <div className="col-md-6" style={ { display: 'flex', justifyContent: 'flex-end' } }>
            { result?.elements[currentStep]?.courseElementId &&
            result.elements[currentStep].done &&
            module?.elements[currentStep + 1] && (
              <div className="single-input-fieldsbtn" onClick={ () => {
                const {
                  match: {
                    params,
                  },
                  history: {
                    push,
                  },
                } = this.props;
                push(`/cursuri/${ params.course }/${ params.moduleId }/${ module?.elements[currentStep + 1]?.link }`);
              } }>
                <input type="submit" value="Pasul următor" />
              </div>
            ) }
          </div>
        </div>

        { element?.application?.ghidaj && (
          <div className="row">
            <div className="col-md-12">
              <h3>Exemplu pentru ghidaj</h3>
              <div className="aplicatie-ghidaj" dangerouslySetInnerHTML={ { __html: element.application.ghidaj } } />
            </div>
          </div>
        ) }
      </form>
    );
  }
}
