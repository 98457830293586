/**
 * Created by mateimisarca on 24/09/2020
 */

import { currencyMap, DEFAULT_CURRENCY } from './../../constants/currency';
import timeZoneFormat from './timeZoneFormat';
import moment from 'moment';

const DEFAULT_LOCALE = 'ro';

const orderValueSet = {
  get k() {
    return 1000;
  },
  get m() {
    return 1000 * 1000;
  },
  get b() {
    return 1000 * 1000 * 1000;
  },
};

const ValueFormattingService = {
  /**
   * Gets value magnitude
   * @param value {number}
   * @param order {number}
   * @returns {string}
   */
  getFormattedOrder: (value, order = 1000) => {
    if (value === 0 || (value < 1000 && order <= 1000)) return '';
    // TODO: Keep an eye out for https://github.com/tc39/proposal-intl-formatToParts
    if (Math.round(value / order) >= 1000) {
      return ValueFormattingService.getFormattedOrder(value, order * 1000);
    }

    if (order <= 1000) {
      return 'k';
    }
    if (order > 1000 && order <= 1000 * 1000) {
      return 'm';
    }
    if (order > 1000 * 1000 && order <= 1000 * 1000 * 1000) {
      return 'b';
    }
    return '';
  },
  /**
   * Returns formatted value
   * @param value {number}
   * @param order { string } [order='']
   * @returns number
   */
  getFormattedValue: (value, order = '') => {
    if (!order || order === '') return value;
    return value >= 1000 && orderValueSet[order]
      ? value / orderValueSet[order]
      : value;
  },
  /**
   * Gets number formatting style
   * @param units {string}
   * @returns {string}
   */
  getFormatStyle(units) {
    switch (units) {
      case '%':
      case 'percent':
        return 'percent';
      case 'text':
        return 'text';
      case 'days':
      case 'number':
        return 'decimal';
      case 'ccy':
      case 'currency':
      case '$':
        return 'currency';
      case 'date':
        return 'date';
      default:
        if (units && currencyMap.includes(v => v[0] === units)) {
          // This handles other currency types
          return 'currency';
        }
        return 'decimal';
    }
  },
  staticUnitsNames: units => {
    switch (units) {
      case 'days':
      case 'h':
        return ` ${ units }`;
      default:
        return '';
    }
  },
  /**
   * Pretty output raw value with appropriate formatting styles
   * Number of digits solution:
   * https://stackoverflow.com/questions/14879691/get-number-of-digits-with-javascript
   * @param value {number}
   * @param units {string}
   * @param options {object<string|number>}
   * @returns {string|number}
   */
  /* eslint-disable */
  prettyFormatValue: (value, units = '', options = {}) => {
    let { precision = null, raw = false } = options;
    const formatStyle = ValueFormattingService.getFormatStyle(units);
    if (typeof value === 'string' && (formatStyle !== 'text' && formatStyle !== 'date')) return value;

    const direction = value / Math.abs(value);
    let order = '';

    if (formatStyle === 'text') {
      return value;
    } else if (formatStyle === 'date') {
      return moment(timeZoneFormat(value)).format('DD-MM-YYYY HH:mm');
    }

    if (!raw) {
      order = ValueFormattingService.getFormattedOrder(Math.abs(value));
    }
    let formattedValue;
    let currency = DEFAULT_CURRENCY;

    /*
        if (units === 'days') {
          order = ` ${units}`;
        }
    */

    if (formatStyle === 'currency' && currencyMap.includes(v => v[0] === units)) {
      currency = currencyMap.find(v => v[0] === units); // eslint-disable-line
    }

    if (formatStyle === 'percent') {
      // Percent values should always be on the [0, 1] range, if values are over 1, get
      // in touch with someone from the data team and let them know
      precision = Math.min(precision === null ? 1 : precision, 1);
      formattedValue = Math.abs(value);// / 100; // eslint-disable-line
      order = '';
    } else {
      formattedValue = ValueFormattingService.getFormattedValue(Math.abs(value), order); // eslint-disable-line
    }

    const maximumFractionDigits = 2; // formattedValue < 100 ? 1 : 0;

    /*
        console.log('@@formattedValue@@ ', formatStyle, formattedValue,
          isNaN(direction) ? formattedValue : formattedValue * direction) + order
          )
    */

    return new Intl.NumberFormat(DEFAULT_LOCALE, {
      style: formatStyle,
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: formatStyle === 'percent' ? precision : maximumFractionDigits,
    }).format(isNaN(direction) ? formattedValue : formattedValue * direction) + order + (ValueFormattingService.staticUnitsNames(units));
  },
};
/* eslint-enable */

export default ValueFormattingService;

