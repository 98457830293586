/**
 * Created by matei on 13/11/2019
 */

import { required, email, match, phoneNumber } from './../../lib/utils/validations';

const registerValidation = (data) => {
  return {
    email: [required, email],
    phone: [required, phoneNumber],
    password: [required],
    passwordConfirmation: [required, match('password')],
  };
};

export default registerValidation;

